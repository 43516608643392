<template>
  <div class="search-container">
    <div class="search-title-container">
      <h1 class="search-title">全球8大站点新品24小时实时智能监测, 实战经验方法论, 助您快速攻克产品开发难题!</h1>
      <!-- <p class="search-title-p">请先选国家再选类目</p> -->
    </div>

    <!-- 搜索表单 -->
    <div class="search-form">
      <el-form :model="searchForm" label-width="120px">
        <div class="left-form">
          <div class="insert-data">
            <el-form-item>
              <div class="category-header">
                <h2>类目</h2><span class="category-header-span">先选国家再选类目</span>
                <el-button @click="toggleCheckAll" type="primary" link class="select-all-btn">
                  {{ searchForm.catagorys.length === catagoryOptions.length ? '取消全选' : '全选' }}
                </el-button>
              </div>
              <div class="category-wrapper" >
                <el-checkbox-group v-model="searchForm.catagorys">
                  <el-checkbox v-for="item in catagoryOptions" :key="item.value" :value="item.value">
                    {{ item.label }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </el-form-item>
          </div>
        </div>

        <!-- 右侧表单部分 -->
        <div class="right-form">
          <div class="insert-data-right">
            <div class="shuiping">
              <!-- 添加国家选择下拉框 -->
              <el-form-item>
                <el-select v-model="searchForm.country" placeholder="请选择国家">
                  <el-option v-for="item in countryOptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>

              <!-- 添加地址输入框 -->
              <el-form-item>
                <el-input v-model="searchForm.brand" placeholder="请输入品牌" />
              </el-form-item>

              <el-form-item>
                <el-input v-model="searchForm.title" placeholder="请输入关键词" />
              </el-form-item>

              <!-- 小类目输入框 -->
              <el-form-item>
                <el-input v-model="searchForm.smallcategorystring" placeholder="输入小类ID" />
              </el-form-item>

              <!-- 店铺反馈 -->
              <el-form-item>
                <el-select v-model="searchForm.parent" placeholder="有无变体">
                  <el-option v-for="item in hasParentASIN" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
              <!-- <span class="nokongxi">-</span> -->
              <el-form-item>
                <el-select v-model="searchForm.duanhuo" placeholder="有无断货">
                  <el-option v-for="item in duanhuovalue" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>

            </div>

            <div class="shuiping">

              <!-- 产品价格 -->
              <el-form-item>
                <el-input v-model="searchForm.minPrice" placeholder="产品价格大于" />
              </el-form-item>
              <span class="nokongxi">-</span>
              <el-form-item>
                <el-input v-model="searchForm.maxPrice" placeholder="产品价格小于" />
              </el-form-item>

              <!-- 产品评价 -->
              <el-form-item>
                <el-input v-model="searchForm.minReview" placeholder="产品评价大于" />
              </el-form-item>
              <span class="nokongxi">-</span>
              <el-form-item>
                <el-input v-model="searchForm.maxReview" placeholder="产品评价小于" />
              </el-form-item>

              <!-- 店铺首页月销 -->
              <el-form-item>
                <el-input v-model="searchForm.minRatingScore" placeholder="产品评分大于" />
              </el-form-item>
              <span class="nokongxi">-</span>
              <el-form-item>
                <el-input v-model="searchForm.maxRatingScore" placeholder="产品评分小于" />
              </el-form-item>

            </div>

            <div class="shuiping">

              <!-- 店铺产品数量 -->
              <el-form-item>
                <el-input v-model="searchForm.minBigCategoryRank" placeholder="大类排名大于" />
              </el-form-item>
              <span class="nokongxi">-</span>
              <el-form-item>
                <el-input v-model="searchForm.maxBigCategoryRank" placeholder="大类排名小于" />
              </el-form-item>

              <!-- 产品月销 -->
              <el-form-item>
                <el-input v-model="searchForm.minMonthSales" placeholder="子体月销大于" />
              </el-form-item>
              <span class="nokongxi">-</span>
              <el-form-item>
                <el-input v-model="searchForm.maxMonthSales" placeholder="子体月销小于" />
              </el-form-item>

              <!-- 产品发货模式 -->
              <el-form-item>
                <el-select v-model="searchForm.fbam" placeholder="发货模式">
                  <el-option v-for="item in fbamOptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>

              <!-- 上架时间 -->
              <el-form-item>
                <el-select v-model="searchForm.alltime" placeholder="上架时间" filterable allow-create
                  :filter-method="filterTime" @create="handleTimeCreate">
                  <el-option v-for="item in alltimeOptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </div>

            <div class="shuiping-store">
              <el-form-item>
                <el-input v-model="searchForm.sellerid" placeholder="店铺追踪,多个店铺ID用英文逗号分隔" />
              </el-form-item>
            </div>

            <div class="storeline">------------------------------ 产品尺寸重量单位 ( 尺寸CM / 克G ) 只需输入数字即可
              ------------------------------</div>

            <div class="shuiping">
              <el-form-item>
                <el-input v-model="searchForm.minItemLength" placeholder="产品长度大于" />
              </el-form-item>
              <span class="nokongxi">-</span>
              <el-form-item>
                <el-input v-model="searchForm.maxItemLength" placeholder="产品长度小于" />
              </el-form-item>

              <el-form-item>
                <el-input v-model="searchForm.minItemWidth" placeholder="产品宽度大于" />
              </el-form-item>
              <span class="nokongxi">-</span>
              <el-form-item>
                <el-input v-model="searchForm.maxItemWidth" placeholder="产品宽度小于" />
              </el-form-item>

              <el-form-item>
                <el-input v-model="searchForm.minItemHeight" placeholder="产品高度大于" />
              </el-form-item>
              <span class="nokongxi">-</span>
              <el-form-item>
                <el-input v-model="searchForm.maxItemHeight" placeholder="产品高度小于" />
              </el-form-item>

            </div>

            <div class="shuiping">

              <!-- 店铺评分 -->
              <el-form-item>
                <el-input v-model="searchForm.minItemWeight" placeholder="商品重量大于" />
              </el-form-item>
              <span class="nokongxi">-</span>
              <el-form-item>
                <el-input v-model="searchForm.maxItemWeight" placeholder="商品重量小于" />
              </el-form-item>

              <!-- 店铺跟卖数量 -->
              <el-form-item>
                <el-input v-model="searchForm.minSellerCount" placeholder="商品跟卖数量大于" />
              </el-form-item>
              <span class="nokongxi">-</span>
              <el-form-item>
                <el-input v-model="searchForm.maxSellerCount" placeholder="商品跟卖数量小于" />
              </el-form-item>

              <el-form-item>
                <el-checkbox v-model="searchForm.order_by" class="sort-checkbox">
                  上架时间
                </el-checkbox>
              </el-form-item>

              <el-form-item>
                <el-checkbox v-model="searchForm.onlyOne" class="sort-checkbox">
                  只显示一个变体
                </el-checkbox>
              </el-form-item>

              <!-- 搜索按钮 -->
              <el-form-item class="button-group-search">
                <el-button type="primary" @click="handleSearchWithReset" :loading="loading" style="width: 100px;">
                  查询
                </el-button>
                <el-button @click="resetForm">
                  重置
                </el-button>
                <el-button @click="openTutorial">
                  <el-icon class="icon">
                    <VideoCamera />
                  </el-icon>
                  使用视频教程
                </el-button>

                <span class="icon-text">想盈利? 请将90%精力聚焦选品! 我们的最新产品库, 可助您试错成本直降80%!</span>

              </el-form-item>
            </div>
          </div>
        </div>
      </el-form>
    </div>

    <!-- 搜索结果 -->
    <div class="search-results" v-loading="loading">
      <div v-show="products.length" class="product-list">
        <div v-for="product in products" :key="product.asin" class="product-card">
          <div class="detail">
            <div class="detailleft">
              <div class="product-image">
                <img 
                  :src="product.ImgSrc + '?t=' + new Date().getTime()" 
                  :key="product.ASIN" 
                  :alt="'产品图片-' + product.ASIN" 
                  style="width: 150px; height: 150px;" 
                />
              </div>
            </div>

            <div class="detailright">
              <div class="product-title">{{ product.Title }}</div>
              <div class="product-detail-info">
                <span class="product-asin">ASIN:</span>
                <span class="product-asin-value">{{ product.ASIN }}</span>
                <span class="product-begintime">上架时间: {{ formatDate(product.BeginTime) }}</span>
                <span class="product-price">价格: {{ product.Price?.toFixed(2) || '-' }}</span>
                <span class="product-review">评论数: {{ product.Review }}</span>
                <span class="product-reviewstar">评分: {{ product.ReviewStar }}</span>
                <span class="product-monthsales">父体月销: {{ product.Sales }}</span>
                <span class="product-monthsales">子体月销: {{ product.MonthSales }}</span>

                <div class="product-store-info">

                  <div class="store-left">
                    <RankChart :daily-ranks="parseRankData(product.MonthRank)" />
                  </div>
                  <div class="store-right">
                    <p class="store-right-p">
                      <span>发货模式: {{ product.FBAM }}</span>
                      <span>类目: {{ product.CatagorysSmall }}</span>
                    </p>
                    <p class="store-right-p">
                      <span v-if="product.FBAFee !== undefined && product.FBAFee !== null && product.FBAFee !== 0">
                        FBA尾程费用: {{ product.FBAFee }}
                      </span>
                      <span v-if="product.MaoLi !== undefined && product.MaoLi !== null && product.MaoLi !== 0">
                        毛利: {{ product.MaoLi > 0 ? product.MaoLi.toFixed(2) + '%' : '0%' }}
                      </span>
                      <!-- <span v-if="product.Genmai !== undefined && product.Genmai !== null && product.Genmai > 1">
                        跟卖数量: {{ product.Genmai }}
                      </span> -->
                      <span
                        v-if="product.BiantiNum !== undefined && product.BiantiNum !== null && product.BiantiNum > 1">
                        变体数量: {{ product.BiantiNum }}
                      </span>
                      <span v-if="product.Weight !== undefined && product.Weight !== null && product.Weight > 0">
                        重量: {{ product.Weight }}克
                      </span>
                      <span v-if="product.brand !== undefined && product.brand !== null && product.brand !== ''">
                        品牌: {{ product.brand }}
                      </span>
                    </p>

                    <!-- <div class="storeinfo-up">
                      <span>品牌: {{ product.product_detail.brand }}</span>
                      <span>发货模式: {{ product.product_detail.fba_type }}</span>
                      <span>主类目: {{ product.product_detail.category?.main?.name }} ({{
                        product.product_detail.category?.main?.rank }})</span>
                      <span>子类目: {{ product.product_detail.category?.sub?.name }} ({{
                        product.product_detail.category?.sub?.rank }})</span>
                    </div>
                    <div class="storeinfo-down">
                      <span>尺寸</span>
                      <span>重量</span>
                      <span>毛利率</span>
                      <span>FBA尾程费用</span>
                    </div> -->
                  </div>
                </div>
                <!-- <div class="dailyRanks">
                  <span>销量排名趋势:</span>
                  <RankChart :daily-ranks="product.product_detail.daily_ranks" />
                </div> -->
              </div>
              <div class="product-button">
                <!-- <span><a :href="`https://www.amazon.${getAmazonDomain(product.country || searchForm.country)}/dp/${product.ASIN}/?th=1&psc=1`" target="_blank">产品详情</a></span> -->

                <span><a :href="`${product.Url}`" target="_blank">产品详情</a></span>


              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 分页组件 -->
      <div class="pagination-container" v-if="products.length && !isFreeUser">
        <el-pagination v-model:current-page="currentPage" v-model:page-size="pageSize" :page-sizes="[30, 50]"
          layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
      </div>

      <!-- 免费用户提示 -->
      <div v-if="products.length && isFreeUser" class="free-user-notice">
        免费用户仅可查看第一页数据，请升级会员查看更多内容
      </div>

      <!-- 空状态提示 -->
      <el-empty v-if="!loading && !products.length" description="请输入搜索条件" />
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, watch, computed, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import api from '@/services/api'
import { debounce } from 'lodash-es'
import RankChart from './RankChart.vue'  // 导入RankChart组件
import { VideoCamera } from '@element-plus/icons-vue'  // 添加图标导入
import dayjs from 'dayjs'
import store from '@/store'

const loading = ref(false)
const products = ref([])
const currentPage = ref(1)
const pageSize = ref(50)
const total = ref(0)

// 添加用户类型状态
const isFreeUser = ref(false)

// 添加一个新的响应式变量存储原始数据
const originalProducts = ref([])

// searchForm定义
const searchForm = reactive({
  catagorys: [],
  country: '1',
  brand: '',
  parent: '',
  duanhuo: '',
  title: '',
  smallcategorystring: '',
  smallcategory: [],
  minItemLength: '',
  maxItemLength: '',
  minItemWidth: '',
  maxItemWidth: '',
  minItemHeight: '',
  maxItemHeight: '',
  minItemWeight: '',
  maxItemWeight: '',
  minRatingScore: '',
  maxRatingScore: '',
  minBigCategoryRank: '',
  maxBigCategoryRank: '',
  minMonthSales: '',
  maxMonthSales: '',
  minReview: '',
  maxReview: '',
  minPrice: '',
  maxPrice: '',
  minSellerCount: '',
  maxSellerCount: '',
  onlyOne: true,
  order_by: false,
  fbam: '',
  alltime: '',
  sellerid: '',
  sellerids: [],
  page: 1,
  pageSize: 50,
})

// 国家选项
const countryOptions = [
  { value: '1', label: '美国' },
  { value: '2', label: '英国' },
  { value: '3', label: '德国' },
  { value: '4', label: '法国' },
  { value: '5', label: '日本' },
  { value: '6', label: '加拿大' },
  { value: '8', label: '意大利' },
  { value: '9', label: '西班牙' },
]

// 不同国家的类目选项
const categoryByCountry = {
  // 美国类目
  '1': [
    { value: '2619525011', label: '家电 : Appliances' },
    { value: '2617941011', label: '艺术 : Arts, Crafts & Sewing' },
    { value: '15684181', label: '汽配 : Automotive' },
    { value: '165796011', label: '母婴 : Baby Products' },
    { value: '3760911', label: '美妆 : Beauty & Personal Care' },
    { value: '2335752011', label: '3C手机配件 : Cell Phones' },
    { value: '7141123011', label: '服装 : Clothing, Shoes & Jewelry' },
    { value: '172282', label: '电子产品 : Electronics' },
    { value: '541966', label: 'PC及周边 : Computers' },
    { value: '16310101', label: '饮食 : Grocery & Gourmet Food' },
    { value: '11260432011', label: '手工 : Handmade Products' },
    { value: '3760901', label: '个护 : Health & Household' },
    { value: '1055398', label: '家居 : Home & Kitchen' },
    { value: '284507', label: '厨房用品 : Kitchen & Dining' },
    { value: '16310091', label: '工业 : Industrial & Scientific' },

    { value: '11091801', label: '乐器 : Musical Instruments' },
    { value: '1064954', label: '办公用品 : Office Products' },
    { value: '2972638011', label: '园艺 : Patio, Lawn & Garden' },
    { value: '2619533011', label: '宠物 : Pet Supplies' },
    { value: '3375251', label: '户外运动 : Sports & Outdoors' },
    { value: '228013', label: '工具 : Tools & Home Improvement' },
    { value: '165793011', label: '玩具 : Toys & Games' },
    { value: '468642', label: '游戏 : Video Games' },
  ],
  // 英国类目
  '2': [
    { value: '1661657031', label: 'APP与游戏 : Apps & Games' },
    { value: '248877031', label: '汽配 : Automotive' },
    { value: '59624031', label: '母婴 : Baby Products' },
    { value: '117332031', label: '美妆 : Beauty' },
    { value: '5866054031', label: '工业科学 : Industry & Science' },
    { value: '340831031', label: '电脑配件 : Computers' },
    { value: '79903031', label: 'DIY工具 : DIY & Tools' },
    { value: '560798', label: '电子摄影 : Electronics & Photo' },
    { value: '11961407031', label: '服装 : Fashion' },
    { value: '11052671', label: '园艺 : Garden' },
    { value: '340834031', label: '饮食 : Grocery' },
    { value: '9699254031', label: '手工 : Handmade Products' },
    { value: '65801031', label: '个护 : Health & Personal Care' },
    { value: '13943654031', label: '居家服务 : Home & Business Services' },
    { value: '3146281', label: '家居庭院 : Home & Garden' },
    { value: '11052681', label: '厨房用品 : Home & Kitchen' },
    { value: '908798031', label: '大型家电 : Large Appliances' },
    { value: '213077031', label: '灯光 : Lighting' },
    { value: '340837031', label: '乐器 : Musical Instruments & DJ' },
    { value: '300703', label: '电脑游戏 : PC & Video Games' },
    { value: '340840031', label: '宠物 : Pet Supplies' },
    { value: '318949011', label: '户外运动 : Sports & Outdoors' },
    { value: '192413031', label: '办公 : Stationery & Office Supplies' },
    { value: '468292', label: '玩具 : Toys & Games' },
  ],

  // 德国类目
  '3': [
    { value: '78191031', label: '汽配 : Auto & Motorrad' },
    { value: '355007011', label: '母婴 : Baby' },
    { value: '80084031', label: '家居建材 : Baumarkt' },
    { value: '213083031', label: '灯光设备 : Beleuchtung' },
    { value: '358556031', label: '酒类 : Bier, Wein & Spirituosen' },
    { value: '186606', label: '书籍 : Bücher' },
    { value: '192416031', label: '办公文具 : Bürobedarf' },
    { value: '340843031', label: '电脑周边 : Computer & Zubehör' },
    { value: '64187031', label: '日化个护 : Drogerie & Körperpflege' },
    { value: '908823031', label: '大家电 : Elektro-Großgeräte' },
    { value: '562066', label: '电子摄影 : Elektronik & Foto' },
    { value: '64274031', label: '成人用品 : Erotik' },
    { value: '11961464031', label: '服饰 : Fashion' },
    { value: '300992', label: '游戏 : Games' },
    { value: '10925031', label: '园艺 : Garten' },
    { value: '1571256031', label: '礼品卡 : Geschenkgutscheine' },
    { value: '5866098031', label: '工业科技 : Gewerbe, Industrie' },
    { value: '9699311031', label: '手工 : Handmade-Produkte' },
    { value: '340852031', label: '宠物 : Haustier' },
    { value: '84230031', label: '美妆 : Kosmetik' },

    { value: '3167641', label: '厨房用具 : Küche, Haushalt' },
    { value: '340846031', label: '饮食 : Lebensmittel & Getränke' },
    { value: '340849031', label: '乐器 : Musikinstrumente & DJ' },
    { value: '12950651', label: '玩具 : Spielzeug' },
    { value: '16435051', label: '户外运动 : Sport & Freizeit' },
  ],

  // 法国类目
  '4': [
    { value: '1571265031', label: '汽配 : Auto et Moto' },
    { value: '197858031', label: '美妆 : Beauté et Parfum' },
    { value: '206617031', label: '母婴用品 : Bébé et Puériculture' },
    { value: '2524127031', label: '礼品卡 : Boutique cartes cadeaux' },
    { value: '590748031', label: 'DIY工具 : Bricolage' },
    { value: '5866109031', label: '工业科技 :Industrie et Science' },
    { value: '57004031', label: '厨房与家居 : Cuisine et Maison' },
    { value: '3635788031', label: '饮食 : Epicerie' },
    { value: '192419031', label: '办公用品 : Fournitures de bureau' },
    { value: '908826031', label: '大型家电 : Gros électroménager' },
    { value: '13921051', label: '高科技 : High-Tech' },
    { value: '197861031', label: '个护健康 : Hygiène et Santé' },
    { value: '340858031', label: '计算机 : Informatique' },
    { value: '340861031', label: '乐器 : Instruments de musique' },
    { value: '3557027031', label: '园艺 : Jardin' },
    { value: '322086011', label: '玩具 : Jeux et Jouets' },
    { value: '301061', label: '图书 : Livres' },
    { value: '530488', label: '软件 : Logiciels' },
    { value: '213080031', label: '灯具照明 : Luminaires et Éclairage' },
    { value: '22942789031', label: '奢侈品 : Luxury Stores' },

    { value: '11961521031', label: '服饰 : Mode' },
    { value: '9699368031', label: '手工 : Produits Handmade' },
    { value: '325614031', label: '户外运动 : Sports et Loisirs' },
  ],

  // 日本类目
  '5': [
    { value: '2016929051', label: 'DIY・工具・ガーデン' },
    { value: '637392', label: '电脑软件 : PCソフト' },
    { value: '2381130051', label: '应用与游戏 : アプリ＆ゲーム' },
    { value: '13299531', label: '玩具 : おもちゃ' },
    { value: '2351652051', label: '礼品卡 : ギフトカード' },
    { value: '637394', label: '游戏 : ゲーム' },
    { value: '14304371', label: '户外运动 : スポーツ＆アウトドア' },
    { value: '2128134051', label: '数字音乐 : デジタルミュージック' },
    { value: '160384011', label: '个护 : ドラッグストア' },
    { value: '2127209051', label: '电脑及周边 : パソコン・周辺機器' },
    { value: '52374051', label: '美妆 : ビューティー' },
    { value: '2229202051', label: '服饰 : ファッション' },
    { value: '2127212051', label: '宠物用品 : ペット用品' },
    { value: '344845011', label: '母婴用品 : ベビー＆マタニティ' },
    { value: '3828871', label: '家居厨房 : ホーム＆キッチン' },
    { value: '2277721051', label: '兴趣爱好 : ホビー' },
    { value: '561956', label: '音乐 : ミュージック' },
    { value: '2277724051', label: '大型家電' },
    { value: '3210981', label: '家电与相机 : 家電＆カメラ' },

    { value: '86731051', label: '办公用品 : 文房具・オフィス用品' },
    { value: '2123629051', label: '乐器 : 楽器・音響機器' },
    { value: '3445393051', label: '工业用品 : 産業・研究開発用品' },
    { value: '2017304051', label: '汽配 : 車＆バイク' },
    { value: '57239051', label: '饮食 : 食品・飲料・お酒' },
  ],

  // 加拿大类目
  '6': [
    { value: '6948389011', label: '汽配: Automotive' },
    { value: '3561346011', label: '母婴 : Baby' },
    { value: '6205124011', label: '美妆 : Beauty & Personal Care' },
    { value: '21204935011', label: '服装 : Clothing, Shoes' },
    { value: '667823011', label: '电子产品 : Electronics' },
    { value: '6967215011', label: '饮食 : Grocery & Gourmet Food' },
    { value: '16708697011', label: '手工 : Handmade' },
    { value: '6205177011', label: '个护 : Health & Personal Care' },
    { value: '2206275011', label: '厨房 : Home & Kitchen' },
    { value: '11076213011', label: '工业 : Industrial & Scientific' },
    { value: '6205496011', label: '珠宝 : Jewellery' },
    { value: '6916844011', label: '乐器 : Musical Instruments' },
    { value: '6205511011', label: '办公用品 :Office Products' },
    { value: '6205499011', label: '园艺 : Patio, Lawn & Garden' },
    { value: '6205514011', label: '宠物用品 : Pet Supplies' },
    { value: '2242989011', label: '户外运动 : Sports & Outdoors' },
    { value: '3006902011', label: '工具 : Tools & Home Improvement' },
    { value: '6205517011', label: '玩具: Toys & Games' },
    { value: '3198031', label: '游戏 : Video Games' },
    { value: '2235620011', label: '手表 : Watches' },
  ],

  // 意大利类目
  '8': [
    { value: '1571280031', label: '汽配: Auto e Moto' },
    { value: '6198082031', label: '美妆 : Bellezza' },
    { value: '9839998031', label: '高端美妆 : Bellezza Premium' },
    { value: '3557017031', label: '礼品卡 : Buoni regalo' },
    { value: '3606310031', label: '办公用品 : Cancelleria e prodotti' },
    { value: '524015031', label: '家居厨房 : Casa e cucina' },
    { value: '5866068031', label: '工业科学 : Commercio, Industria' },
    { value: '12598749031', label: '数字音乐 : Dispositivi Amazon & Accessori' },
    { value: '412609031', label: '电子产品 : Elettronica' },
    { value: '2454160031', label: 'DIY工具 :Fai da te' },
    { value: '635016031', label: '庭院 : Giardino e giardinaggio' },
    { value: '523997031', label: '玩具 : Giochi e giocattoli' },
    { value: '14437356031', label: '大型家电 : Grandi elettrodomestici' },
    { value: '1571292031', label: '照明设备 : Illuminazione' },
    { value: '425916031', label: '计算机 : Informatica' },
    { value: '5512286031', label: '时尚服饰 : Moda' },
    { value: '9699425031', label: '手工 : Prodotti Handmade' },
    { value: '12472499031', label: '宠物用品: animali domestici' },
    { value: '1571289031', label: '个护健康 : della persona' },

    { value: '524012031', label: '户外运动 : Sport e tempo libero' },
    { value: '3628629031', label: '乐器 : Strumenti musicali' },
    { value: '412603031', label: '视频游戏 : Videogiochi' },
  ],

  // 西班牙类目
  '9': [
    { value: '1703495031', label: '母婴: Bebé' },
    { value: '6198054031', label: '美妆 : Belleza' },
    { value: '3564279031', label: '礼品卡 : Cheques regalo' },
    { value: '1951051031', label: '汽配 : Coche y moto' },
    { value: '2454136031', label: '运动户外 : Deportes y aire libre' },
    { value: '599370031', label: '电子产品 : Electrónica' },
    { value: '4772050031', label: '大型家电 : electrodomésticos' },
    { value: '599391031', label: '家居厨房 : Hogar y cocina' },
    { value: '3564289031', label: '照明设备 : Iluminación' },
    { value: '5866088031', label: '工业科技 : Industria, empresas' },
    { value: '667049031', label: '计算机 : Informática' },
    { value: '3628866031', label: '乐器 : Instrumentos musicales' },
    { value: '1571259031', label: '园艺 : Jardín' },
    { value: '599385031', label: '玩具 : Juguetes y juegos' },
    { value: '599364031', label: '兴趣爱好 : Libros' },
    { value: '5512276031', label: '服饰 : Moda' },
    { value: '3628728031', label: '办公用品 : Oficina' },
    { value: '667040031', label: '家电与相机 : Otros Productos' },

    { value: '9699482031', label: '手工 : Productos Handmade' },
    { value: '12472654031', label: '乐器 : Productos para mascotas' },
    { value: '3677430031', label: '个护 : Salud y cuidado personal' },
    { value: '599382031', label: '视频游戏 : Videojuegos' },
  ],
  // 默认使用美国类目（如果其他国家未定义）
  'default': []
};

// 初始化美国的类目选项
const catagoryOptions = ref(categoryByCountry['1']);

// 监听国家变化
watch(() => searchForm.country, (newCountry) => {
  // 清空已选类目
  searchForm.catagorys = [];

  // 更新类目选项
  if (categoryByCountry[newCountry]) {
    catagoryOptions.value = categoryByCountry[newCountry];
  } else {
    // 如果没有该国家的类目数据，使用默认（美国）类目
    catagoryOptions.value = categoryByCountry['1'];
    ElMessage.warning('该国家的类目数据正在完善中，暂时显示美国类目');
  }
});

// 发货模式选项
const hasParentASIN = [
  { value: 'all', label: '都包含' },
  { value: 'false', label: '无变体' },
  { value: 'true', label: '有变体' }
]

// 断货选项
const duanhuovalue = [
  { value: '0', label: '都包含' },
  { value: '1', label: '无断货' },
  { value: '-1', label: '有断货' }
]

// 发货模式选项
const fbamOptions = [
  { value: 'all', label: '所有发货模式' },
  { value: 'FBA', label: 'FBA' },
  { value: 'FBM', label: 'FBM' }
]

// 上架时间选项
const alltimeOptions = [
  { value: '0', label: '上架时间' },
  { value: '30', label: '最近30天' },
  { value: '60', label: '最近60天' },
  { value: '90', label: '最近90天' },
  { value: '180', label: '最近180天' },
  { value: '365', label: '最近1年' },
]

// 格式化日期
const formatDate = (dateString) => {
  if (!dateString) return '';
  try {
    return dayjs(dateString).format('YYYY-MM-DD');
  } catch (e) {
    // console.error('日期格式化错误:', e);
    return dateString;
  }
}

// 根据国家代码获取亚马逊域名
const getAmazonDomain = (countryCode) => {
  const domainMap = {
    '1': 'com',      // 美国
    '2': 'co.uk',    // 英国
    '3': 'de',       // 德国
    '4': 'fr',       // 法国
    '5': 'co.jp',    // 日本
    '6': 'ca',       // 加拿大
    '8': 'it',       // 意大利
    '9': 'es'        // 西班牙
  }
  return domainMap[countryCode] || 'com' // 默认返回美国域名
}

// 搜索方法
const handleSearch = debounce(async () => {
  try {
    loading.value = true

    // 如果是免费用户且尝试访问第一页以外的页面，强制回到第一页
    if (isFreeUser.value && currentPage.value > 1) {
      currentPage.value = 1
      ElMessage.warning('免费用户仅可查看第一页数据，请升级会员查看更多内容')
    }

    const searchParams = {
      catagorys: searchForm.catagorys,
      country: searchForm.country,
      brand: searchForm.brand?.trim(),
      parent: searchForm.parent,
      duanhuo: searchForm.duanhuo,
      title: searchForm.title?.trim(),
      smallcategory: searchForm.smallcategorystring ? [searchForm.smallcategorystring] : [],
      sellerids: searchForm.sellerid ? [searchForm.sellerid] : [],
      minItemLength: Number(searchForm.minItemLength) || undefined,
      maxItemLength: Number(searchForm.maxItemLength) || undefined,
      minItemWidth: Number(searchForm.minItemWidth) || undefined,
      maxItemWidth: Number(searchForm.maxItemWidth) || undefined,
      minItemHeight: Number(searchForm.minItemHeight) || undefined,
      maxItemHeight: Number(searchForm.maxItemHeight) || undefined,
      minItemWeight: Number(searchForm.minItemWeight) || undefined,
      maxItemWeight: Number(searchForm.maxItemWeight) || undefined,
      minRatingScore: Number(searchForm.minRatingScore) || undefined,
      maxRatingScore: Number(searchForm.maxRatingScore) || undefined,
      minBigCategoryRank: Number(searchForm.minBigCategoryRank) || undefined,
      maxBigCategoryRank: Number(searchForm.maxBigCategoryRank) || undefined,
      minMonthSales: Number(searchForm.minMonthSales) || undefined,
      maxMonthSales: Number(searchForm.maxMonthSales) || undefined,
      minReview: Number(searchForm.minReview) || undefined,
      maxReview: Number(searchForm.maxReview) || undefined,
      minPrice: Number(searchForm.minPrice) || undefined,
      maxPrice: Number(searchForm.maxPrice) || undefined,
      minSellerCount: Number(searchForm.minSellerCount) || undefined,
      maxSellerCount: Number(searchForm.maxSellerCount) || undefined,
      fbam: searchForm.fbam,
      alltime: Number(searchForm.alltime) || undefined,
      onlyOne: Boolean(searchForm.onlyOne),
      page: Number(currentPage.value),
      pageSize: Number(pageSize.value),
      order_by: searchForm.order_by,
    }

    // order_by: searchForm.order_by,

    // 修改空值清理逻辑
    Object.keys(searchParams).forEach(key => {
      if (searchParams[key] === undefined ||
        (Array.isArray(searchParams[key]) && searchParams[key].length === 0)) {
        delete searchParams[key]
      }
    })

    const response = await api.searchNewProducts(searchParams)

    // console.log('搜索结果后端:', response)

    if (response.code === 200) {
      // 修改数据处理逻辑，适应不同的数据结构
      if (response.data) {
        // 如果 response.data 本身是数组
        if (Array.isArray(response.data)) {
          originalProducts.value = response.data  // 存储原始数据
          products.value = filterProducts(response.data)  // 应用筛选
          total.value = response.total
        }
        // 如果 response.data 是包含 data 属性的对象
        else if (response.data.data && Array.isArray(response.data.data)) {
          originalProducts.value = response.data.data  // 存储原始数据
          products.value = filterProducts(response.data.data)  // 应用筛选
          total.value = response.total
        }
        else {
          originalProducts.value = []
          products.value = []
          total.value = 0
        }
      } else {
        originalProducts.value = []
        products.value = []
        total.value = 0
      }
      total.value = response.total
    } else {
      ElMessage.error(response.message || '搜索失败')
    }

    // 获取用户信息
    await checkUserType()

  } catch (error) {
    ElMessage.error(error.message || '搜索失败')
  } finally {
    loading.value = false
  }
}, 500)

// 创建一个新的搜索方法，用于条件改变时的搜索
const handleSearchWithReset = () => {
  currentPage.value = 1  // 只在条件改变时重置页码
  handleSearch()
}

// 分页方法
const handleCurrentChange = (val) => {
  // 如果是免费用户且尝试访问第一页以外的页面，阻止操作
  if (isFreeUser.value && val > 1) {
    ElMessage.warning('免费用户仅可查看第一页数据，请升级会员查看更多内容')
    currentPage.value = 1
    return
  }

  currentPage.value = val
  handleSearch()  // 使用普通搜索方法，不重置页码
}

const handleSizeChange = (val) => {
  pageSize.value = val
  currentPage.value = 1  // 改变每页数量时重置到第一页
  handleSearch()
}

// 重置方法
const resetForm = () => {
  Object.assign(searchForm, {
    catagorys: [],
    country: '1',  // 默认美国
    brand: '',  // 修正字段名
    title: '',
    smallcategorystring: '',  // 重置为空数组
    sellerid: '',
    parent: '',
    duanhuo: '',
    minItemLength: '',
    maxItemLength: '',
    minItemWidth: '',
    maxItemWidth: '',
    minItemHeight: '',
    maxItemHeight: '',
    minItemWeight: '',
    maxItemWeight: '',
    minRatingScore: '',
    maxRatingScore: '',
    minBigCategoryRank: '',
    maxBigCategoryRank: '',
    minMonthSales: '',
    maxMonthSales: '',
    minReview: '',
    maxReview: '',
    minPrice: '',
    maxPrice: '',
    minSellerCount: '',
    maxSellerCount: '',
    onlyOne: true,
    order_by: false,
    fbam: '',  // 发货模式
    alltime: '', // 上架时间
    page: 1,
    pageSize: 50,
  })

  // 重置分页相关数据
  currentPage.value = 1
  pageSize.value = 50
  total.value = 0
  products.value = []
}

// 切换全选方法
const toggleCheckAll = () => {
  if (searchForm.catagorys.length === catagoryOptions.value.length) {
    searchForm.catagorys = []  // 如果已全选，则清空
  } else {
    searchForm.catagorys = catagoryOptions.value.map(item => item.value)  // 否则全选
  }
}

const filterTime = (query) => {
  if (query !== '') {
    const num = parseInt(query)
    if (!isNaN(num) && num > 0) {
      return [{
        value: String(num),
        label: `最近${num}天`
      }]
    }
  }
  return alltimeOptions
}

const handleTimeCreate = (query) => {
  const num = parseInt(query)
  if (!isNaN(num) && num > 0) {
    searchForm.alltime = String(num)
  } else {
    searchForm.alltime = ''
  }
}

const parseRankData = (rankStr) => {
  try {
    const data = JSON.parse(rankStr || '[]')
    // 转换为 RankChart 需要的格式
    return {
      'main': data.map(item => ({
        date: item.date,  // 注意这里是小写的 date
        avgRank: item.rank  // 注意这里是小写的 rank
      }))
    }
  } catch (e) {
    // console.error('解析排名数据失败:', e)
    return {}
  }
}

// 添加打开教程的方法
const openTutorial = () => {
  window.open('https://mp.weixin.qq.com/s/sKaLB0VTlQR-uU0gp8OQxw', '_blank')
}

// 添加检查用户类型的方法
const checkUserType = () => {
  try {
    const userRole = store.state.user?.role || localStorage.getItem('userRole')
    // console.log("用户角色:", userRole)
    if (userRole) {
      isFreeUser.value = Number(userRole) === 6
      // console.log("是否是免费用户:", isFreeUser.value)
      return
    }

    // 如果没有角色信息，默认为免费用户
    isFreeUser.value = true
    // console.log("未找到用户角色信息，默认设为免费用户:", isFreeUser.value)
  } catch (error) {
    // 如果出错，默认为免费用户
    isFreeUser.value = true
    // console.error("检查用户类型出错:", error)
  }
}

// 在组件挂载时检查用户类型
onMounted(async () => {
  await checkUserType()
})

// 添加一个筛选函数
const filterProducts = (products) => {
  return products.filter(product => {
    // 筛选价格
    if (searchForm.minPrice && product.Price < Number(searchForm.minPrice)) {
      return false
    }
    if (searchForm.maxPrice && product.Price > Number(searchForm.maxPrice)) {
      return false
    }

    // 筛选评价数量
    if (searchForm.minReview && product.Review < Number(searchForm.minReview)) {
      return false
    }
    if (searchForm.maxReview && product.Review > Number(searchForm.maxReview)) {
      return false
    }

    // 筛选发货模式
    if (searchForm.fbam && searchForm.fbam !== 'all' && product.FBAM !== searchForm.fbam) {
      return false
    }

    // 筛选上架时间
    if (searchForm.alltime && searchForm.alltime !== '0') {
      const beginTime = dayjs(product.BeginTime)
      const now = dayjs()
      const days = Number(searchForm.alltime)

      // 检查是否在指定天数内上架
      if (now.diff(beginTime, 'day') > days) {
        return false
      }
    }

    // 通过所有筛选条件
    return true
  })
}

// 添加watch以响应筛选条件变化
watch([
  () => searchForm.minPrice,
  () => searchForm.maxPrice,
  () => searchForm.minReview,
  () => searchForm.maxReview,
  () => searchForm.fbam,
  () => searchForm.alltime
], () => {
  // 当筛选条件变化时，重新应用筛选
  products.value = filterProducts(originalProducts.value)
})

</script>

<style scoped>
.search-title-container {
  margin: 0;
  margin-top: 60px;
  padding: 35px;
  background-color: #fdf6ec;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

.search-title {
  margin: 0;
  font-size: 22px;
  font-weight: 700;
  color: #606266;
  text-align: center;
}

.search-container {
  margin: 0;
  padding: 0;
  width: 100%;
}

.search-form {
  max-width: 1200px;
  margin: 20px auto;
  padding: 15px;
  background-color: #f0f8ff;
  border-radius: 8px;
}

.search-results {
  max-width: 1200px;
  padding: 15px;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.search-title-p {
  font-size: 16px;
  color: red;
  padding-bottom: 0;
  margin-bottom: 0;
  font-weight: 700;
}

:deep(.el-form) {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 0;
}

.left-form {
  width: calc(25% - 10px);
  flex-shrink: 0;
}

.right-form {
  width: calc(75% - 10px);
  flex-shrink: 0;
  background-color: #f0f8ff;
}

.insert-data {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px 0;
  padding-left: 10px;
  padding-right: 10px;
  /* height: 330px; */
  /*overflow-y: auto;*/
  /* scrollbar-width: thin; */
  /* scrollbar-color: #dcdfe6 transparent; */
}

.left-form :deep(.el-form-item__content) {
  margin-left: 0 !important;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-right: 5px;
}

.category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 15px; */
  width: 100%;
  box-sizing: border-box;
}

.category-header h2 {
  margin: 0;
  padding-left: 9px;
  font-size: 16px;
  color: #606266;
}

.select-all-btn {
  padding: 0;
}

.category-wrapper {
  width: 100%;
  box-sizing: border-box;
  height: 285px;
  overflow-y: auto;
  overflow-x: hidden;
  /* 添加Firefox的滚动条样式 */
  scrollbar-width: thin;
  scrollbar-color: #dcdfe6 transparent;
}

.category-wrapper::-webkit-scrollbar {
  width: 6px !important;
  display: block; /* 确保滚动条显示 */
}

.category-wrapper::-webkit-scrollbar-thumb {
  background-color: #dcdfe6;
  border-radius: 3px;
  min-height: 40px; /* 增加滑块最小高度 */
  border: 4px solid transparent; /* 增加边距 */
  background-clip: content-box; /* 确保背景色不延伸到边框 */
}

.category-wrapper::-webkit-scrollbar-track {
  background: transparent;
}

.category-wrapper :deep(.el-checkbox-group) {
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 100%;
}

.category-wrapper :deep(.el-checkbox) {
  margin: 0;
  height: 32px;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 8px;
}

.category-wrapper :deep(.el-checkbox):hover {
  background-color: #f5f7fa;
  border-radius: 4px;
}

.insert-data-right {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  border-radius: 8px;
}

.insert-data-right :deep(.el-form-item) {
  margin: 0 !important;
  width: calc((100% - 75px) / 6) !important;
  box-sizing: border-box;
}

.insert-data-right :deep(.el-form-item__content) {
  margin-left: 0 !important;
  width: 100%;
}

.insert-data-right :deep(.el-select),
.insert-data-right :deep(.el-input),
.insert-data-right :deep(.el-input-number) {
  width: 100%;
}

.nokongxi {
  margin: 0 -11px;
}

.storeline {
  width: 100%;
  text-align: center;
  padding-top: 13px;
  padding-bottom: 15px;
  font-size: 12px;
  margin-top: -5px;
  margin-bottom: -8px;
  color: #606266;
}

.shuiping {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 15px;
  padding: 0;
  flex-wrap: wrap;
}

.shuiping :deep(.el-form-item) {
  margin: 0;
}

.shuiping :deep(.el-form-item__content) {
  margin-left: 0 !important;
  width: 100%;
}

.shuiping :deep(.el-select),
.shuiping :deep(.el-input) {
  width: 100%;
}

.pagination-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  background-color: #ffffff;
  border-radius: 4px;
}

.shuiping :deep(.button-group-search) {
  width: auto !important;
}

.detail {
  display: flex;
  gap: 20px;
  width: 100% !important;
  height: 180px;
  margin-bottom: 15px;
}

.detailleft {
  width: 20% !important;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 8px;
}

.detailright {
  width: 80%;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px;
  color: #333333;
  font-size: 14px;
  position: relative;
  min-height: 150px;
}

.product-image {
  width: 100%;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

.product-image img {
  max-width: calc(100% - 20px);
  max-height: calc(100% - 20px);
  object-fit: contain;
}

.product-title {
  font-size: 14px;
  font-weight: 700;
  color: #606266;
  width: 90%;
  height: 50px;
}

.product-asin {
  border-radius: 3px;
  background-color: #f90;
  color: #fff;
  font-size: 12px;
  padding: 5px 10px;
  margin-right: 10px;
}

.product-asin-value {
  color: #f90;
  font-size: 14px;
  margin-right: 10px;
}

.product-price,
.product-review,
.product-reviewstar,
.product-monthsales,
.product-begintime {
  padding: 0 10px;
}

.product-store {
  border-radius: 3px;
  background-color: #a0cfff;
  color: #fff;
  font-size: 12px;
  padding: 5px 10px;
  margin-right: 10px;
}

.product-store a {
  color: #333333;
}

.product-store-info {
  height: 42px;
  font-size: 12px;
  border-radius: 5px;
  margin-top: 20px;
  background-color: #f5f5f5;
  padding: 10px;
  display: flex;
  gap: 15px;
}

.store-left {
  width: 20%;
  padding-top: 10px;
}

.store-right {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.storeinfo-up {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.storeinfo-down {
  display: flex;
  gap: 10px;
}

.store-right-p {
  padding: 0;
  margin: 0;

}

.category-header-span {
  color: red;
}

/* .product-store-info div.storeinfo-up {
  padding-bottom: 10px;
} */

.product-store-info div span {
  padding-right: 10px;
}

.product-button {
  position: absolute;
  bottom: 15px;
  right: 15px;
  padding: 5px 5px;
  background-color: #f90;
  color: #ffffff;
  border-radius: 4px 0 4px 0;
}

.product-button span {
  padding: 0 10px;
}

.product-button span a {
  color: #ffffff;
  font-size: 13px;
}

.shuiping-store {
  width: 100%;
  display: flex;
}

.shuiping-store :deep(.el-form-item) {
  width: 100% !important;
  margin: 0;
}

.shuiping-store :deep(.el-form-item__content) {
  margin-left: 0 !important;
  width: 100%;
}

.shuiping-store :deep(.el-input) {
  width: 100%;
}

.icon {
  margin-right: 5px;
  vertical-align: middle;
}

.icon-text {
  padding-left: 10px;
  /* vertical-align: middle; */
  color: red;
}

.free-user-notice {
  margin-top: 10px;
  color: #f56c6c;
  font-size: 14px;
  text-align: center;
}

.pagination-container :deep(.el-pagination.is-disabled .el-pager li:not(.is-active)) {
  color: #c0c4cc;
  cursor: not-allowed;
}

.category-wrapper .el-form-item {
  margin-bottom: 0 !important;
}

.el-form-item {
  margin-bottom: 0 !important;
}
</style>
