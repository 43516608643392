import { createStore } from 'vuex'

export default createStore({
  state() {
    return {
      user: null,
      token: localStorage.getItem('token') || null,
      refresh: localStorage.getItem('refresh') || null,
      refreshtokenexpiry: localStorage.getItem('refresh_token_expiry') || null,
      lastlogin: localStorage.getItem('last_login') || null,
      userRole: localStorage.getItem('userRole') || null
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setToken(state, { token, refresh }) {
      state.token = token
      state.refresh = refresh
      localStorage.setItem('token', token)
      localStorage.setItem('refresh', refresh)
    },
    setRefreshTokenExpiry(state, refreshtokenexpiry) {
      state.refreshtokenexpiry = refreshtokenexpiry
      localStorage.setItem('refresh_token_expiry', refreshtokenexpiry)
    },
    setLastLogin(state, lastlogin) {
      state.lastlogin = lastlogin
      localStorage.setItem('last_login', lastlogin)
    },
    logout(state) {
      state.user = null
      state.token = null
      state.refresh = null
      localStorage.removeItem('token')
      localStorage.removeItem('refresh')
      localStorage.removeItem('refresh_token_expiry')
      localStorage.removeItem('last_login')
      localStorage.removeItem('userRole')
    },
    login(state, userData) {
      state.user = {
        email: userData.user,
        role: userData.role,
        // 其他用户信息...
      }
      
      // 存储用户角色到 localStorage
      localStorage.setItem('userRole', userData.role)
      
      // 其他登录逻辑...
    }
  },
  actions: {
    login({ commit }, { user, token, refresh, refreshtokenexpiry, lastlogin, role }) {
      commit('setUser', { email: user, role: role })
      commit('setToken', { token, refresh })
      commit('setRefreshTokenExpiry', refreshtokenexpiry)
      commit('setLastLogin', lastlogin)
      
      // 存储用户角色到 localStorage
      localStorage.setItem('userRole', role)
    },
    logout({ commit }) {
      commit('logout')
    }
  },
  getters: {
    isAuthenticated: state => !!state.token
  }
})
