import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './services/api'

// Element Plus 相关导入
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'highlight.js/styles/github.css'


const app = createApp(App)

// 全局注册 Element Plus
app.use(ElementPlus, {
    locale: zhCn,
})

// 将 router 注入到 app 的全局属性中
app.config.globalProperties.$router = router
// 将 api 注入到 app 的全局属性中
app.config.globalProperties.$api = api
app.use(router).use(store).mount('#app')
