<template>
  <div class="page-wrapper">
    <div class="page-container">
      <div class="image-container">
        <img src="@/assets/images/logo.png" alt="logo" class="logo-image">
      </div>

      <div class="register">
        <h2>用户注册</h2>
        <el-form :model="form" :rules="rules" ref="formRef" label-width="100px">

          <el-form-item label="邮箱" prop="email">
            <el-input v-model="form.email" type="email" />
          </el-form-item>

          <el-form-item label="手机号码" prop="phone">
            <el-input v-model="form.phone" />
          </el-form-item>

          <el-form-item label="密码" prop="password">
            <el-input v-model="form.password" :type="passwordVisible ? 'text' : 'password'" class="password-input">
              <template #suffix>
                <el-icon class="password-icon" @click="passwordVisible = !passwordVisible">
                  <Hide v-if="passwordVisible" />
                  <View v-else />
                </el-icon>
              </template>
            </el-input>
          </el-form-item>

          <el-form-item label="确认密码" prop="confirm_password">
            <el-input v-model="form.confirm_password" :type="password2Visible ? 'text' : 'password'"
              class="password-input">
              <template #suffix>
                <el-icon class="password-icon" @click="password2Visible = !password2Visible">
                  <Hide v-if="password2Visible" />
                  <View v-else />
                </el-icon>
              </template>
            </el-input>
          </el-form-item>

          <el-form-item label="验证码" prop="captcha_code">
            <div class="captcha-input-group">
              <el-input v-model="form.captcha_code" placeholder="请输入大写字母" class="captcha-input" />
              <div class="captcha-append">
                <el-button @click="refreshCaptcha" class="refresh-button">
                  <el-icon :size="25">
                    <Refresh />
                  </el-icon>
                </el-button>
                <img :src="captchaUrl" @click="refreshCaptcha" alt="验证码" v-if="captchaUrl" class="captcha-image" />
              </div>
            </div>
          </el-form-item>

          <el-form-item>
            <div class="button-group">
              <el-button type="primary" @click="submitForm(formRef)">注册</el-button>
              <div class="links">
                <router-link to="/login" class="link">登录</router-link>
                <router-link to="/forgotpw" class="link">找回密码</router-link>
                <router-link to="/" class="link">返回首页</router-link>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import {
  ElForm,
  ElFormItem,
  ElInput,
  ElButton,
  ElMessage
} from 'element-plus'
import api from '@/services/api'
import { Refresh, Hide, View } from '@element-plus/icons-vue'  // 添加 Hide 和 View 图标

const router = useRouter()

const formRef = ref(null)
const captchaUrl = ref('')
const captchaKey = ref('')

const form = reactive({
  email: '',
  phone: '',
  password: '',
  confirm_password: '',
  captcha_code: ''
})

const validatePassword = (rule, value, callback) => {
  if (value.length < 8) {
    callback(new Error('密码长度必须至少为8个字符'))
  } else {
    if (form.confirm_password !== '') {
      formRef.value?.validateField('confirm_password')
    }
    callback()
  }
}

const validatePassword2 = (rule, value, callback) => {
  if (value !== form.password) {
    callback(new Error('两次输入的密码不一致'))
  } else {
    callback()
  }
}

const validatePhone = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入手机号码'))
  } else if (!/^1[3-9]\d{9}$/.test(value)) {
    callback(new Error('请输入有效的手机号'))
  } else {
    callback()
  }
}

const rules = reactive({
  // username: [
  //   { required: true, message: '请输入用户名', trigger: 'blur' },
  //   { min: 2, max: 20, message: '用户名长度应在2-20个字符之间', trigger: 'blur' }
  // ],
  email: [
    { required: true, message: '请输入邮箱地址', trigger: 'blur' },
    { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }
  ],
  password: [
    { required: true, message: '请输入密码', trigger: 'blur' },
    { validator: validatePassword, trigger: 'blur' }
  ],
  confirm_password: [
    { required: true, message: '请确认密码', trigger: 'blur' },
    { validator: validatePassword2, trigger: 'blur' }
  ],
  phone: [
    { required: true, message: '请输入手机号码', trigger: 'blur' },
    { validator: validatePhone, trigger: 'blur' }
  ],
  captcha_code: [
    { required: true, message: '请输入验证码', trigger: 'blur' }
  ]
})

// 将验证码刷新移到 onMounted 中
onMounted(() => {
  refreshCaptcha()
})

const refreshCaptcha = async () => {
  try {
    const response = await api.getCaptcha()
    captchaUrl.value = response.data.data.imageBase64
    captchaKey.value = response.data.data.captcha_id
    // console.log('获取到的验证码ID:', captchaKey.value)
  } catch (error) {
    ElMessage.error('获取验证码失败，请刷新页面重试')
  }
}

const submitForm = async (formEl) => {
  if (!formEl) return

  await formEl.validate(async (valid) => {
    if (valid) {
      try {
        const registerData = {
          ...form,
          captcha_id: captchaKey.value
        }
        
        // console.log('发送注册请求数据:', {
        //   email: registerData.email,
        //   captcha_id: registerData.captcha_id,
        //   captcha_code: registerData.captcha_code
        // })

        const response = await api.register(registerData)
        // console.log('Response Register', response)

        if (response.data.code === 4010) {
          ElMessage.error(response.data.message)
          refreshCaptcha()
        }

        else if (response.data.code === 4001) {
          ElMessage.error(response.data.message)
          refreshCaptcha()
        }

        else if (response.data.code === 4002) {
          ElMessage.error(response.data.message)
          refreshCaptcha()
        }

        else if (response.data.code === 4003) {
          ElMessage.error(response.data.message)
          refreshCaptcha()
        }

        else if (response.data.code === 4005) {
          ElMessage.error(response.data.message)
          refreshCaptcha()
        }

        else if (response.data.code === 2000) {
          ElMessage.success('注册成功')
          localStorage.setItem('rememberedUsername', form.email)
          localStorage.setItem('rememberedPassword', form.password)
          router.push('/login')
        }


      } catch (error) {
        if (error.response?.data?.error) {
          const errors = error.response.data.error
          Object.values(errors).forEach(errorMessage => {
            ElMessage.error(errorMessage)
          })
        } else {
          ElMessage.error('注册失败，请重试')
        }
        refreshCaptcha()
      }
    }
  })
}

// 添加密码显示状态控制
const passwordVisible = ref(false)
const password2Visible = ref(false)
</script>

<style scoped>
.error-message {
  color: red;
}

.captcha-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 100px;
  /* 固定宽 */
  background-color: #fff;
  border-radius: 0 4px 4px 0;
  /* 添加右侧圆角 */
  overflow: hidden;
  /* 确保图片不会超出圆角 */
}

.captcha-image {
  height: 38px;
  width: 100px;
  cursor: pointer;
  display: block;
}

/* 刷新按钮样式 */
:deep(.refresh-button) {
  border: none;
  padding: 8px;
  height: 100%;
  color: var(--el-text-color-regular);
  border-right: 1px solid var(--el-border-color);
  /* 添加右边框 */
  border-radius: 0;
  /* 移除按圆角 */
}

:deep(.el-input-group__append) {
  padding: 0;
  overflow: hidden;
  border-radius: 0 4px 4px 0;
  border: 1px solid var(--el-border-color);
  border-left: none;
  background-color: var(--el-fill-color-blank);
  display: flex;
  /* 使用 flex 布局 */
  align-items: center;
  /* 垂直居中 */
}

:deep(.el-input__wrapper) {
  height: 38px;
}

/* 确保输入框右侧圆角被移除 */
:deep(.el-input__wrapper) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.captcha-input-group {
  display: flex;
  align-items: center;
}

.captcha-input {
  flex: 1;
}

.captcha-append {
  display: flex;
  align-items: center;
  height: 36px;
  /* 减小高度 */
  margin-left: -1px;
  border: 1px solid var(--el-border-color);
  border-radius: 0 4px 4px 0;
  background-color: var(--el-fill-color-blank);
  padding: 0 2px;
}

.refresh-button {
  height: 100%;
  width: 40px;
  padding: 0;
  border: none;
  border-right: 1px solid var(--el-border-color);
  border-radius: 0;
  color: var(--el-text-color-regular);
  display: flex;
  align-items: center;
  justify-content: center;
}

.captcha-image {
  height: 34px;
  /* 减小高度 */
  width: 90px;
  cursor: pointer;
  object-fit: cover;
  margin: 0 7px;
}

/* 调整所有输入框的高度 */
:deep(.el-input__wrapper) {
  height: 36px;
  /* 减小高度 */
  border-radius: 4px;
}

/* 只针对验证码输入框移除右侧圆角 */
.captcha-input :deep(.el-input__wrapper) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* 密码框样式保持不变 */
.password-icon {
  cursor: pointer;
  color: var(--el-text-color-placeholder);
}

.password-icon:hover {
  color: var(--el-text-color-regular);
}

.password-input :deep(.el-input__suffix) {
  cursor: pointer;
  padding: 0 8px;
  display: flex;
  align-items: center;
}
</style>

<style scoped>
/* 外层包装容器，用于垂直居中 */
.page-wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 20px; */
  box-sizing: border-box;
  background-color: #f5f7fa;
}

/* 页面容器样式 */
.page-container {
  display: flex;
  width: 100%;
  height: auto;
  /* 改为自动高度 */
  min-height: 300px;
  /* 设置最小高度 */
  max-width: 1080px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

/* 左侧图片容器 */
.image-container {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f7fa;
  border-radius: 4px 0 0 4px;
  overflow: hidden;
}

.logo-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* 右侧注册表单容器 */
.register {
  width: 45%;
  padding: 30px 30px 30px 10px;
  /* 上 右 下 左 内边距 */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 0 4px 4px 0;
}

.register h2 {
  padding-left: 30px;
  font-size: 24px;
  font-weight: 600;
}

/* 其他现有样式保持不变 */

.button-group {
  display: flex;
  align-items: center;
  gap: 15px;
  /* 元素之间的间距 */
}

.links {
  display: flex;
  gap: 10px;
  /* 链接之间的间距 */
}

.link {
  font-size: 14px;
  color: var(--el-text-color-regular);
  text-decoration: none;
}

.link:hover {
  color: var(--el-color-primary);
}
</style>
