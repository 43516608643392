import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'
import Login from '../components/Login.vue'
import Register from '../components/Register.vue'
import UserProfile from '../components/UserProfile.vue'
import PasswordReset from '../components/PasswordReset.vue'
import ForgotPassword from '../components/ForgotPassword.vue'
import PasswordResetConfirm from '@/components/PasswordResetConfirm.vue'
import ChatWindow from '@/components/ChatWindow.vue'
import Default from '../layouts/Default.vue'
import CreateForm from '../components/CreateForm.vue'
import SearchAsin from '../components/SearchAsin.vue'
import StoreAsinSearch from '../components/StoreAsinSearch.vue'
import NewProduct from '../components/NewProduct.vue'
import Bond999 from '../components/Bond999.vue'
import StoreRealTime from '../components/StoreRealTime.vue'


const routes = [
  {
    path: '/',
    component: Default,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home,
        meta: {
          title: '亚马逊选品, 亚马逊产品开发 - TOPAI123',
          description: 'TOPAI123是一家为亚马逊跨境卖家提供软件工具的公司, 基于大数据和人工智能技术, 可以帮助卖家查询每个产品的销量、关键词、自然搜索数据, 并提供一站式选品、市场分析、关键词',
          keywords: '亚马逊选品,亚马逊产品开发,亚马逊智能工具,亚马逊竞品分析,TOPAI123.COM'
        }
      },
      {
        path: '/profile',
        name: 'UserProfile',
        component: UserProfile,
        meta: {
          title: '用户中心 - TOPAI123',
          description: 'TOPAI123用户中心',
          requiresAuth: true
        }
      },

      // 需要认证的路由
      {
        path: '/bond999',
        name: 'Bond999',
        component: Bond999,
        meta: {
          requiresAuth: true,  // 需要登录
          requiresAdmin: true  // 需要管理员权限
        }
      },

      // 添加到 Default 布局的子路由中
      {
        path: 'asin/create',
        name: 'AsinCreate',
        component: CreateForm,
        meta: {
          requiresAuth: true,
          title: 'ASIN录入 - TOPAI123'
        }
      },
      {
        path: '/storesearch',
        name: 'StoreRealTime',
        component: StoreRealTime,
        meta: {
          requiresAuth: false,
          title: '亚马逊店铺产品开发 - TOPAI123',
          description: 'TOPAI123亚马逊店铺产品开发页面'
        }
      },
      {
        path: '/newproduct',
        name: 'NewProduct',
        component: NewProduct,
        meta: {
          requiresAuth: false
        }
      },
    ]
  },

  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: '用户登录 - TOPAI123',
      description: 'TOPAI123用户登录页面',
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      title: '用户注册 - TOPAI123',
      description: 'TOPAI123用户注册页面',
    }
  },
  {
    path: '/forgotpw',
    name: 'ForgotPassword',
    component: ForgotPassword
  },

  {
    path: '/reset-password',
    name: 'PasswordResetConfirm',
    component: PasswordResetConfirm,
    props: route => ({ token: route.query.token })
  },

  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: PasswordReset
  },

  {
    path: '/chat',
    name: 'Chat',
    component: ChatWindow,
    meta: { requiresAuth: true }
  },

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'TOPAI123'

  const metaDescription = document.querySelector('meta[name="description"]')
  if (metaDescription) {
    metaDescription.setAttribute('content', to.meta.description || '')
  }

  const isAuthenticated = !!localStorage.getItem('token')
  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router
