<!-- src/components/Bond999.vue -->
<template>
    <div class="bond999-panel">
      <h2>TOPAI123.COM 永久长红!!!</h2>
      <div v-if="isLoading">加载中...</div>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
  
      <!-- 添加搜索栏 -->
      <el-card class="user-list-card">
        <template #header>
          <div class="card-header">
            <span>用户列表</span>
            <div class="search-box">
              <el-input
                v-model="searchQuery"
                placeholder="搜索邮箱/手机号"
                class="search-input"
                clearable
                @clear="handleSearchClear"
                @keyup.enter="handleSearch"
              >
                <template #append>
                  <el-button @click="handleSearch">
                    <el-icon><Search /></el-icon>
                  </el-button>
                </template>
              </el-input>
              <el-button type="primary" @click="refreshUserList">
                刷新列表
              </el-button>
            </div>
          </div>
        </template>
        
        <el-table 
          :data="userList" 
          stripe 
          style="width: 100%"
          v-loading="tableLoading"
        >
          <el-table-column prop="email" label="邮箱" min-width="180" />
          <el-table-column prop="phone" label="手机号" width="120" />
          <el-table-column prop="points" label="积分" width="80" />
          <el-table-column prop="chajian_id" label="插件" width="80" />
          <el-table-column prop="invite_code" label="邀请码" width="120" />
          <el-table-column prop="role_id" label="角色" width="80">
            <template #default="scope">
              {{ scope.row.role_id }}
            </template>
          </el-table-column>
          <el-table-column prop="created_at" label="注册时间" min-width="120">
            <template #default="scope">
              {{ formatDateTime(scope.row.created_at) }}
            </template>
          </el-table-column>
          <el-table-column prop="last_login_at" label="最后登录" min-width="120">
            <template #default="scope">
              {{ formatDateTime(scope.row.last_login_at) }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150" fixed="right">
            <template #default="scope">
              <el-button size="small" @click="handleEdit(scope.row)">编辑</el-button>
              <el-button 
                size="small" 
                type="danger" 
                @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
  
      <!-- 添加分页组件 -->
      <div class="pagination-container">
        <el-pagination
          v-model:current-page="currentPage"
          v-model:page-size="pageSize"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
  
      <!-- 添加编辑对话框 -->
      <el-dialog
        v-model="editDialogVisible"
        title="编辑用户信息"
        width="800px"
      >
        <el-form
          v-if="editingUser"
          :model="editingUser"
          label-width="100px"
          :rules="rules"
          ref="editFormRef"
        >
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="editingUser.email" disabled />
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="editingUser.phone" />
          </el-form-item>
          <el-form-item label="积分" prop="points">
            <el-input-number v-model="editingUser.points" :min="0" />
          </el-form-item>
          <el-form-item label="插件" prop="chajian_id">
            <el-select v-model="editingUser.chajian_id">
              <el-option label="免费" :value="0" />
              <el-option label="VIP年用户" :value="1" />
            </el-select>
          </el-form-item>
          <el-form-item label="邀请码" prop="invite_code">
            <el-input v-model="editingUser.invite_code" />
          </el-form-item>
          <el-form-item label="角色" prop="role_id">
            <el-select v-model="editingUser.role_id">
              <el-option label="VIP年用户" :value="2" />
              <el-option label="半年用户" :value="3" />
              <el-option label="季度用户" :value="4" />
              <el-option label="月度用户" :value="5" />
              <el-option label="免费用户" :value="6" />
            </el-select>
          </el-form-item>

          <el-form-item label="开始时间" prop="begin_at">
            <div class="date-picker-with-button">
              <el-date-picker
                v-model="editingUser.begin_at"
                type="datetime"
                placeholder="选择开始时间"
              />
              <el-button type="primary" size="small" @click="setBeginTimeToNow">
                设为当前时间
              </el-button>
            </div>
          </el-form-item>

          <el-form-item label="结束时间" prop="end_at">
            <div class="date-picker-with-button">
              <el-date-picker
                v-model="editingUser.end_at"
                type="datetime"
                placeholder="选择结束时间"
              />
              <el-button type="primary" size="small" @click="setEndTimeToNow">
                设为当前时间
              </el-button>
            </div>
          </el-form-item>

          <el-form-item label="状态" prop="status">
            <el-switch
              v-model="editingUser.status"
              :active-value="1"
              :inactive-value="0"
            />
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="editDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="submitEdit">确定</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, computed } from 'vue'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import { useRouter } from 'vue-router'
  import { Search } from '@element-plus/icons-vue'
  import vipapi from '@/services/vipapi'
  
  const user = ref(null)
  const stats = ref({})
  const userList = ref([])  // 新增用户列表数据
  const isLoading = ref(true)
  const errorMessage = ref('')
  const router = useRouter()
  
  // 添加编辑相关的响应式变量
  const editDialogVisible = ref(false)
  const editingUser = ref(null)
  const editFormRef = ref(null)
  
  // 表单验证规则
  const rules = {
    phone: [
      { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
    ],
    points: [
      { type: 'number', min: 0, message: '积分不能小于0', trigger: 'change' }
    ],
    role_id: [
      { required: true, message: '请选择角色', trigger: 'change' }
    ]
  }
  
  // 添加搜索相关的响应式变量
  const searchQuery = ref('')
  const tableLoading = ref(false)
  
  // 添加分页相关的响应式变量
  const currentPage = ref(1)
  const pageSize = ref(50)
  const total = ref(0)
  
  // 过滤用户列表
  const filteredUserList = computed(() => {
    if (!searchQuery.value) return userList.value
    
    const query = searchQuery.value.toLowerCase()
    return userList.value.filter(user => 
      user.email.toLowerCase().includes(query) ||
      user.phone.includes(query)
    )
  })
  
  // 获取管理员信息和统计数据
  const fetchBond999Data = async () => {
    isLoading.value = true
    errorMessage.value = ''
    try {
      const response = await vipapi.getBond999()
      // console.log('管理员ADMIN Response', response)
      
      if (response.data.code === 2000) {
        user.value = response.data.data[0]
        stats.value = response.data.stats || {}  // 添加默认空对象
      } else {
        ElMessage.error(response.data.message || '获取数据失败')
      }
    } catch (error) {
      if (error.response?.status === 429) {
        errorMessage.value = '请求过于频繁，请稍后再试'
      } else {
        errorMessage.value = '获取数据失败，请重试'
      }
    } finally {
      isLoading.value = false
    }
  }
  
  // 刷新统计数据
  const refreshStats = async () => {
    try {
      const response = await vipapi.getBond999()
      if (response.data.code === 2000) {
        stats.value = response.data.data.stats
        ElMessage.success('数据已更新')
      }
    } catch (error) {
      ElMessage.warning('统计数据加载失败')
    }
  }
  
  // 获取用户列表
  const fetchUserList = async () => {
    tableLoading.value = true
    try {
      const params = new URLSearchParams({
        page: currentPage.value,
        pageSize: pageSize.value
      })
      if (searchQuery.value) {
        params.append('query', searchQuery.value)
      }
      
      const response = await vipapi.get(`/bond999/search?${params}`)
      if (response.data.code === 2000) {
        userList.value = response.data.data.list
        total.value = response.data.data.total
        if (userList.value.length === 0 && searchQuery.value) {
          ElMessage.warning('未找到匹配的用户')
        }
      } else {
        ElMessage.error(response.data.msg || '获取用户列表失败')
      }
    } catch (error) {
      ElMessage.error('获取用户列表失败')
    } finally {
      tableLoading.value = false
    }
  }
  
  // 刷新用户列表
  const refreshUserList = () => {
    fetchUserList()
  }
  
  // 处理编辑用户
  const handleEdit = (row) => {
    // 创建一个副本，并转换时间字符串为 Date 对象
    editingUser.value = {
      ...row,
      begin_at: row.begin_at ? new Date(row.begin_at) : null,
      end_at: row.end_at ? new Date(row.end_at) : null
    }
    editDialogVisible.value = true
  }
  
  // 添加时间格式化函数
  const formatDate = (date) => {
    if (!date) return ''
    const d = new Date(date)
    const year = d.getFullYear()
    const month = String(d.getMonth() + 1).padStart(2, '0')
    const day = String(d.getDate()).padStart(2, '0')
    const hours = String(d.getHours()).padStart(2, '0')
    const minutes = String(d.getMinutes()).padStart(2, '0')
    const seconds = String(d.getSeconds()).padStart(2, '0')
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }
  
  // 提交编辑
  const submitEdit = async () => {
    if (!editFormRef.value) return
    
    try {
      await editFormRef.value.validate()
      
      const updateData = {
        email: editingUser.value.email,
        phone: editingUser.value.phone,
        points: editingUser.value.points,
        role_id: editingUser.value.role_id,
        chajian_id: editingUser.value.chajian_id,
        begin_at: formatDate(editingUser.value.begin_at),
        end_at: formatDate(editingUser.value.end_at),
        invite_code: editingUser.value.invite_code,
        status: editingUser.value.status
      }
      
      // console.log('提交的更新数据:', updateData)
      
      const response = await vipapi.put('/bond999/user', updateData)
      // console.log('更新响应:', response.data)
      
      if (response.data.code === 2000) {
        ElMessage.success('更新成功')
        editDialogVisible.value = false
        fetchUserList()
      } else {
        ElMessage.error(response.data.msg || '更新失败')
      }
    } catch (error) {
      // console.error('编辑用户错误:', error)
      if (error.response) {
        // console.error('错误响应:', error.response.data)
        ElMessage.error(error.response.data.msg || '更新失败')
      } else {
        ElMessage.error('表单验证失败，请检查输入')
      }
    }
  }
  
  // 处理删除用户
  const handleDelete = (row) => {
    ElMessageBox.confirm(
      `确定要删除用户 ${row.email} 吗？`,
      '警告',
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }
    ).then(async () => {
      try {
        const response = await vipapi.delete(`/bond999/user?id=${row.id}`)
        if (response.data.code === 0) {
          ElMessage.success('删除成功')
          fetchUserList() // 刷新列表
        } else {
          ElMessage.error(response.data.msg || '删除失败')
        }
      } catch (error) {
        // console.error('删除用户错误:', error)
        ElMessage.error('删除失败')
      }
    }).catch(() => {})
  }
  
  // 添加时间格式化函数
  const formatDateTime = (dateTimeStr) => {
    if (!dateTimeStr) return ''
    const date = new Date(dateTimeStr)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')
    
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }
  
  // 处理搜索
  const handleSearch = async () => {
    currentPage.value = 1  // 重置页码
    await fetchUserList()
  }
  
  // 处理清除搜索
  const handleSearchClear = () => {
    searchQuery.value = ''
    fetchUserList()  // 清除搜索时重新获取所有用户
  }
  
  // 处理页码改变
  const handleCurrentChange = (val) => {
    currentPage.value = val
    fetchUserList()
  }
  
  // 处理每页条数改变
  const handleSizeChange = (val) => {
    pageSize.value = val
    currentPage.value = 1
    fetchUserList()
  }
  
  // 设置开始时间为当前时间
  const setBeginTimeToNow = () => {
    editingUser.value.begin_at = new Date()
  }
  
  // 设置结束时间为当前时间
  const setEndTimeToNow = () => {
    editingUser.value.end_at = new Date()
  }
  
  onMounted(() => {
    fetchBond999Data()
    fetchUserList()  // 添加获取用户列表
  })
  </script>
  
  <style scoped>
  .bond999-panel {
    padding: 20px;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }

  h2{
    margin-top: 60px;
    background-color: #fdf6ec;
    text-align: center;
    padding: 20px;
    border-radius: 8px;
    color: red;
  }
  

  .statistics-card {
    margin-top: 20px;
  }
  
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .statistics-content {
    padding: 20px 0;
  }
  
  .stat-item {
    text-align: center;
    padding: 20px;
    background-color: #f5f7fa;
    border-radius: 4px;
  }
  
  .stat-item h3 {
    margin: 0 0 10px 0;
    color: #606266;
  }
  
  .stat-item p {
    font-size: 24px;
    color: #409EFF;
    margin: 0;
  }
  
  .error-message {
    color: #f56c6c;
    margin-top: 10px;
  }
  
  .user-list-card {
    margin-top: 20px;
  }
  
  .el-table {
    margin-top: 15px;
  }
  
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .search-box {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .search-input {
    width: 300px;
  }
  
  /* 确保图标垂直居中 */
  .el-input-group__append {
    display: flex;
    align-items: center;
  }
  
  .pagination-container {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
  
  .date-picker-with-button {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  </style>