<template>
  <div class="form-container">
    <div class="asin-form">
      <el-form :model="formData" :rules="rules" ref="formRef" label-width="120px">
        <!-- 基本信息 -->
        <el-form-item label="ASIN" prop="asin">
          <el-input v-model="formData.asin" placeholder="请输入ASIN"></el-input>
        </el-form-item>

        <el-form-item label="上架日期" prop="begintime">
          <el-input 
            v-model="formData.begintime" 
            placeholder="格式：YYYY-MM-DD，如：2024-03-20"
            @blur="calculateAlltime"
          ></el-input>
        </el-form-item>

        <el-form-item label="上架天数" prop="alltime">
          <el-input-number 
            v-model="formData.alltime" 
            :min="0"
            :controls="false"
            placeholder="系统自动计算"
            :disabled="true"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="标题" prop="title">
          <el-input v-model="formData.title" placeholder="请输入商品标题"></el-input>
        </el-form-item>

        <el-form-item label="关键词" prop="keywords">
          <el-input v-model="formData.keywords" placeholder="请输入关键词"></el-input>
        </el-form-item>

        <!-- 价格和销量信息 -->
        <el-form-item label="价格" prop="price">
          <el-input-number v-model="formData.price" :min="0" :precision="2"></el-input-number>
        </el-form-item>

        <el-form-item label="月销量" prop="monthsales">
          <el-input-number v-model="formData.monthsales" :min="0"></el-input-number>
        </el-form-item>

        <!-- 评论信息 -->
        <el-form-item label="评论数" prop="review">
          <el-input-number v-model="formData.review" :min="0"></el-input-number>
        </el-form-item>

        <el-form-item label="评分" prop="reviewstar">
          <el-rate v-model="formData.reviewstar" :max="5" :allow-half="true"></el-rate>
        </el-form-item>

        <!-- 类目选择 -->
        <el-form-item label="类目" prop="catagorys">
          <el-select v-model="formData.catagorys">
            <el-option label="Electronics" value="Electronics"></el-option>
            <el-option label="Home" value="Home"></el-option>
            <el-option label="Clothing" value="Clothing"></el-option>
            <el-option label="Beauty" value="Beauty"></el-option>
          </el-select>
        </el-form-item>

        <!-- FBA状态 -->
        <el-form-item label="FBA状态" prop="fbam">
          <el-select v-model="formData.fbam">
            <el-option label="FBA" value="FBA"></el-option>
            <el-option label="FBM" value="FBM"></el-option>
            <el-option label="AMZ" value="AMZ"></el-option>
          </el-select>
        </el-form-item>

        <!-- 店铺信息 -->
        <el-divider>店铺信息</el-divider>

        <el-form-item label="店铺ID" prop="storeid">
          <el-input v-model="formData.storeid" placeholder="请输入店铺ID"></el-input>
        </el-form-item>

        <el-form-item label="店铺名称" prop="storename">
          <el-input v-model="formData.storename" placeholder="请输入店铺名称"></el-input>
        </el-form-item>

        <el-form-item label="店铺评分" prop="storestar">
          <el-rate v-model="formData.storestar" :max="5" :allow-half="true"></el-rate>
        </el-form-item>

        <el-form-item label="店铺地址" prop="storeaddress">
          <el-input v-model="formData.storeaddress" placeholder="请输入店铺地址"></el-input>
        </el-form-item>

        <el-form-item label="店铺反馈" prop="storefeedback">
          <el-input v-model="formData.storefeedback" placeholder="请输入店铺反馈"></el-input>
        </el-form-item>

        <el-form-item label="国家" prop="storecountry">
          <el-input v-model="formData.storecountry" placeholder="请输入国家"></el-input>
        </el-form-item>

        <el-form-item label="省份" prop="storeshengfen">
          <el-input v-model="formData.storeshengfen" placeholder="请输入省份"></el-input>
        </el-form-item>

        <el-form-item label="城市" prop="storecity">
          <el-input v-model="formData.storecity" placeholder="请输入城市"></el-input>
        </el-form-item>

        <el-form-item label="邮编" prop="storeyoubian">
          <el-input v-model="formData.storeyoubian" placeholder="请输入邮编"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm">提交</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { ElMessage, ElDivider } from 'element-plus'
import { useRouter } from 'vue-router'
import api from '@/services/api'
import dayjs from 'dayjs'

const router = useRouter()
const formRef = ref(null)

const formData = reactive({
  // 基本信息
  asin: '',
  title: '',
  price: 0,
  monthsales: 0,
  catagorys: '',
  fbam: 'FBA',
  review: 0,
  reviewstar: 0,
  keywords: '',
  storeid: '',
  storename: '',
  
  // 系统字段
  shard_key: '',
  result: 'success',
  pagenumber: 1,
  rankandtype: 'default',
  lanhai: 'normal',
  lanhaibl: 'normal',
  lanhaisales: 0,
  fbm: 'normal',
  avfbm: 0,
  avPrice: 0,
  avReview: 0,
  allitems: 0,
  imgsrc: 'default.jpg',
  allsales: 0,
  
  // 店铺信息
  storestar: 0,
  storeaddress: '',
  storefeedback: '',
  storecountry: '',
  storeshengfen: '',
  storecity: '',
  storeyoubian: '',
  sales: 0,
  
  // 时间和链接
  alltime: '',
  begintime: '',
  catalink: ''
})

const rules = {
  asin: [{ required: true, message: '请输入ASIN', trigger: 'blur' }],
  title: [{ required: true, message: '请输入商品标题', trigger: 'blur' }],
  price: [{ required: true, message: '请输入价格', trigger: 'blur' }],
  catagorys: [{ required: true, message: '请选择类目', trigger: 'change' }],
  keywords: [{ required: true, message: '请输入关键词', trigger: 'blur' }],
  storeid: [{ required: true, message: '请输入店铺ID', trigger: 'blur' }],
  storename: [{ required: true, message: '请输入店铺名称', trigger: 'blur' }],
  storeaddress: [{ required: true, message: '请输入店铺地址', trigger: 'blur' }],
  storefeedback: [{ required: true, message: '请输入店铺反馈', trigger: 'blur' }],
  storecountry: [{ required: true, message: '请输入国家', trigger: 'blur' }],
  storeshengfen: [{ required: true, message: '请输入省份', trigger: 'blur' }],
  storecity: [{ required: true, message: '请输入城市', trigger: 'blur' }],
  storeyoubian: [{ required: true, message: '请输入邮编', trigger: 'blur' }],
  begintime: [
    { required: true, message: '请输入上架日期', trigger: 'blur' },
    { 
      pattern: /^\d{4}-\d{2}-\d{2}$/,
      message: '日期格式必须为 YYYY-MM-DD',
      trigger: 'blur'
    }
  ],
  alltime: [
    { required: true, message: '上架天数不能为空', trigger: 'blur' }
  ]
}

const calculateAlltime = () => {
  const { begintime } = formData
  if (begintime && /^\d{4}-\d{2}-\d{2}$/.test(begintime)) {
    const start = dayjs(begintime)
    const now = dayjs()
    if (start.isValid()) {
      if (start.isAfter(now)) {
        ElMessage.warning('上架日期不能晚于今天')
        formData.begintime = now.format('YYYY-MM-DD')
        formData.alltime = 0
      } else {
        formData.alltime = now.diff(start, 'day')
      }
    } else {
      formData.alltime = 0
    }
  } else if (begintime === '0' || !begintime) {
    formData.alltime = 0
    formData.begintime = '0'
  }
}

const submitForm = async () => {
  if (!formRef.value) return
  
  try {
    await formRef.value.validate()
    
    // 准备提交数据
    const submitData = {
      ...formData,
      // 字符串类型的字段
      shard_key: formData.catagorys || 'default',
      result: 'success',
      rankandtype: 'default',
      lanhai: 'normal',
      lanhaibl: 'normal',
      fbm: 'normal',
      
      // 数值类型的字段
      pagenumber: 1,
      lanhaisales: Number(formData.monthsales) || 0,
      avfbm: 0,
      avPrice: Number(formData.price) || 0,
      avReview: Number(formData.review) || 0,
      allitems: 0,
      allsales: Number(formData.monthsales) || 0,
      sales: Number(formData.monthsales) || 0,
      
      // 其他必需字段
      imgsrc: 'default.jpg',
      catalink: `https://www.amazon.com/dp/${formData.asin}`,
      
      // 确保日期和天数正确传递
      begintime: formData.begintime || '0',
      alltime: Number(formData.alltime) || 0,
    }

    // 确保数值字段为数字类型
    const numberFields = ['price', 'monthsales', 'review', 'reviewstar', 'storestar', 'alltime']
    numberFields.forEach(field => {
      submitData[field] = Number(submitData[field]) || 0
    })

    console.log('提交数据:', submitData)

    const response = await api.createAsin(submitData)
    
    if (response.data.code === 200) {
      ElMessage.success('数据录入成功')
      resetForm()
    } else {
      ElMessage.error(response.data.message || '录入失败')
    }
  } catch (error) {
    console.error('错误详情:', error.response?.data)
    ElMessage.error(error.response?.data?.message || '提交失败')
  }
}

const resetForm = () => {
  if (formRef.value) {
    formRef.value.resetFields()
  }
}
</script>

<style scoped>
.form-container {
  padding-top: 80px;
  min-height: 100vh;
  background-color: #f5f7fa;
}

.asin-form {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 768px) {
  .asin-form {
    max-width: 95%;
    margin: 0 auto;
    padding: 15px;
  }
}

:deep(.el-form-item__label) {
  font-weight: 500;
}

:deep(.el-input), :deep(.el-select) {
  width: 100%;
}

:deep(.el-input-number) {
  width: 180px;
}

:deep(.el-form-item:last-child) {
  margin-bottom: 0;
  text-align: center;
}

:deep(.el-button) {
  padding: 12px 30px;
}

:deep(.el-divider__text) {
  font-size: 16px;
  font-weight: 500;
  color: #409EFF;
}
</style>