import axios from 'axios'
import { ElMessage } from 'element-plus'

const vipapi = axios.create({
    baseURL: 'https://www.topai123.com/api/',
    // baseURL: 'http://localhost:8000/api/',
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    withCredentials: true
})

// 请求拦截器
vipapi.interceptors.request.use(config => {
    const token = localStorage.getItem('token');

    if (token && token !== 'null') {
        config.headers = {
            ...config.headers,
            'Authorization': `Bearer ${token}`
        };
        return config;
    } else {
        // console.warn('没有找到有效的token');
        return Promise.reject(new Error('请先登录'));
    }
}, error => {
    return Promise.reject(error);
});

// 响应拦截器
vipapi.interceptors.response.use(response => {
    if (response.data.code === 4012) {
        localStorage.removeItem('token')
        localStorage.removeItem('refresh')
        localStorage.removeItem('refresh_token_expiry')
        localStorage.removeItem('last_login')
        ElMessage.error(response.data.message)
        window.location.href = '/login'
        return Promise.reject(new Error(response.data.message))
    }
    return response;
}, error => {
    // console.error('请求失败:', error.response || error);
    return Promise.reject(error);
});

// 添加方法
const api = {
    getBond999() {
        return vipapi.get('bond999')
    },
    get: (url) => vipapi.get(url),
    put: (url, data) => vipapi.put(url, data),
    delete: (url) => vipapi.delete(url)
}

export default api 