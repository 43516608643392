<template>
  <div class="page-wrapper">
    <div class="page-container">
      <div class="image-container">
        <img src="@/assets/images/logo.png" alt="logo" class="logo-image">
      </div>

      <div class="login">
        <h2>用户登录</h2>
        <el-form :model="form" :rules="rules" ref="formRef" label-width="100px">
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="form.email" />
          </el-form-item>

          <el-form-item label="密码" prop="password">
            <el-input v-model="form.password" :type="passwordVisible ? 'text' : 'password'" class="password-input">
              <template #suffix>
                <el-icon class="password-icon" @click="passwordVisible = !passwordVisible">
                  <Hide v-if="passwordVisible" />
                  <View v-else />
                </el-icon>
              </template>
            </el-input>
          </el-form-item>

          <el-form-item label="验证码" prop="captcha_code">
            <div class="captcha-input-group">
              <el-input v-model="form.captcha_code" placeholder="请输入大写字母" class="captcha-input" />
              <div class="captcha-append">
                <el-button @click="refreshCaptcha" class="refresh-button">
                  <el-icon :size="25">
                    <Refresh />
                  </el-icon>
                </el-button>

                <template v-if="captchaUrl">
                  <img :src="captchaUrl" @click="refreshCaptcha" alt="验证码" class="captcha-image" />
                </template>
                <template v-else>
                  <div class="captcha-loading">
                    <el-icon class="is-loading">
                      <Loading />
                    </el-icon>
                    <span>加载中...</span>
                  </div>
                </template>
              </div>
            </div>
          </el-form-item>

          <el-form-item>
            <el-checkbox v-model="form.remember">记住我</el-checkbox>
          </el-form-item>

          <el-form-item>
            <div class="button-group">
              <el-button type="primary" @click="submitForm(formRef)" :loading="isLoading">
                {{ isLoading ? '登录中...' : '登录' }}
              </el-button>
              <div class="links">
                <router-link to="/register" class="link">注册账号</router-link>
                <router-link to="/forgotpw" class="link">忘记密码？</router-link>
                <router-link to="/" class="link">返回首页</router-link>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { Refresh, Hide, View, Loading } from '@element-plus/icons-vue'
import api from '@/services/api'

const store = useStore()
const router = useRouter()
const formRef = ref(null)
const isLoading = ref(false)
const captchaUrl = ref('')
const captchaKey = ref('')
const passwordVisible = ref(false)
const errorMessage = ref('')

const form = reactive({
  email: '',
  password: '',
  captcha_code: '',
  remember: true
})

const rules = reactive({
  email: [
    { required: true, message: '请输入邮箱', trigger: 'blur' }
  ],
  password: [
    { required: true, message: '请输入密码', trigger: 'blur' }
  ],
  captcha_code: [
    { required: true, message: '请输入验证码', trigger: 'blur' }
  ]
})


const refreshCaptcha = async () => {
  try {
    const response = await api.getCaptcha()
    // Sconsole.log('获取验证码响应:', response.data)
    if (response.data.code === 200 && response.data.data) {
      captchaUrl.value = response.data.data.imageBase64
      captchaKey.value = response.data.data.captcha_id
      // console.log('保存验证码ID:', captchaKey.value)
    } else {
      ElMessage.error('获取验证码失败')
    }
  } catch (error) {
    // console.error('获取验证码错误:', error)
    ElMessage.error('获取验证码失败，请刷新页面重试')
  }
}

const submitForm = async (formEl) => {
  if (!formEl) return

  await formEl.validate(async (valid) => {
    if (valid) {
      if (!captchaKey.value) {
        ElMessage.error('验证码已失效，请点击刷新')
        return
      }
      
      isLoading.value = true
      try {
        // console.log('提交的验证码数据:', {
        //   captcha_code: form.captcha_code,
        //   captcha_id: captchaKey.value
        // })
        
        const response = await api.login({
          email: form.email,
          password: form.password,
          captcha_code: form.captcha_code,
          captcha_id: captchaKey.value
        })

        if (response.data.code === 4010) {
          ElMessage.error(response.data.message)
          refreshCaptcha()
        }

        else if (response.data.code === 4001) {
          ElMessage.error(response.data.message)
          refreshCaptcha()
        }

        else if (response.data.code === 4002) {
          ElMessage.error(response.data.message)
          refreshCaptcha()
        }

        else if (response.data.code === 4003) {
          ElMessage.error(response.data.message)
          refreshCaptcha()
        }

        else if (response.data.code === 4014) {
          ElMessage.error(response.data.message)
          refreshCaptcha()
        }

        else if (response.data.code === 4015) {
          ElMessage.error(response.data.message)
          refreshCaptcha()
        }

        else if (response.data.code === 2000) {

          store.dispatch('login', {
            user: response.data.data.user.email,
            token: response.data.data.tokens.access_token,
            refresh: response.data.data.tokens.refresh_token,
            refreshtokenexpiry: response.data.data.tokens.refresh_token_expiry,
            lastlogin: response.data.data.user.last_login,
            role: response.data.data.user.role
          })

          // console.log('refreshTimestamp:', response.data.refresh_token_expiry);
          // console.log('登录响应数据:', response.data);
          if (form.remember) {
            localStorage.setItem('rememberedUsername', form.email)
            localStorage.setItem('rememberedPassword', form.password)
          } else {
            localStorage.removeItem('rememberedUsername')
            localStorage.removeItem('rememberedPassword')
          }

          ElMessage.success('登录成功')
          // console.log('登录成功', response.data.data.user.redirect)
          router.push(response.data.data.user.redirect)

        }

      } catch (error) {
        if (error.response?.data) {
          const errorMessages = Object.values(error.response.data).flat()
          errorMessages.forEach(message => {
            ElMessage.error(message)
          })
        } else {
          ElMessage.error('登录失败，请重试')
        }
        refreshCaptcha()
      } finally {
        isLoading.value = false
      }
    }
  })
}

onMounted(() => {
  const rememberedUsername = localStorage.getItem('rememberedUsername')
  const rememberedPassword = localStorage.getItem('rememberedPassword')
  if (rememberedUsername && rememberedPassword) {
    form.email = rememberedUsername
    form.password = rememberedPassword
    form.remember = true
  }
  refreshCaptcha()
})
</script>

<style scoped>
/* 外层包装容器，用于垂直居中 */
.page-wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: #f5f7fa;
}

/* 页面容器样式 */
.page-container {
  display: flex;
  width: 100%;
  height: auto;
  min-height: 300px;
  max-width: 1080px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

/* 左侧图片容器 */
.image-container {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f7fa;
  border-radius: 4px 0 0 4px;
  overflow: hidden;
}

.logo-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* 右侧登录表单容器 */
.login {
  width: 45%;
  padding: 30px 30px 30px 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 0 4px 4px 0;
}

.login h2 {
  padding-left: 30px;
  font-size: 24px;
  font-weight: 600;
}

/* 验证码相关样式 */
.captcha-loading {
  display: flex;
  align-items: center;
  gap: 5px;
  height: 34px;
  width: 90px;
  margin: 0 7px;
  color: var(--el-text-color-secondary);
  font-size: 12px;
}

.captcha-input-group {
  display: flex;
  align-items: center;
}

.captcha-input {
  flex: 1;
}

.captcha-append {
  display: flex;
  align-items: center;
  height: 36px;
  margin-left: -1px;
  border: 1px solid var(--el-border-color);
  border-radius: 0 4px 4px 0;
  background-color: var(--el-fill-color-blank);
  padding: 0 2px;
}

.refresh-button {
  height: 100%;
  width: 40px;
  padding: 0;
  border: none;
  border-right: 1px solid var(--el-border-color);
  border-radius: 0;
  color: var(--el-text-color-regular);
  display: flex;
  align-items: center;
  justify-content: center;
}

.captcha-image {
  height: 34px;
  width: 90px;
  cursor: pointer;
  object-fit: cover;
  margin: 0 7px;
}

/* 输入框样式 */
:deep(.el-input__wrapper) {
  height: 36px;
  border-radius: 4px;
}

.captcha-input :deep(.el-input__wrapper) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* 密码框样式 */
.password-icon {
  cursor: pointer;
  color: var(--el-text-color-placeholder);
}

.password-icon:hover {
  color: var(--el-text-color-regular);
}

.password-input :deep(.el-input__suffix) {
  cursor: pointer;
  padding: 0 8px;
  display: flex;
  align-items: center;
}

/* 按钮组和链接样式 */
.button-group {
  display: flex;
  align-items: center;
  gap: 15px;
}

.links {
  display: flex;
  gap: 10px;
}

.link {
  font-size: 14px;
  color: var(--el-text-color-regular);
  text-decoration: none;
}

.link:hover {
  color: var(--el-color-primary);
}
</style>
