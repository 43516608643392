<template>
  <div class="password-reset">
    <h2>重置密码</h2>
    <form @submit.prevent="resetPassword" v-if="!resetSent">
      <div>
        <label for="email">邮箱：</label>
        <input type="email" id="email" v-model="email" required>
      </div>
      <button type="submit" :disabled="isLoading">{{ isLoading ? '发送中...' : '发送重置链接' }}</button>
    </form>
    <div v-else>
      <p class="success-message">重置链接已发送到您的邮箱，请查收。</p>
    </div>
    <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import api from '@/services/api'

const email = ref('')
const resetSent = ref(false)
const isLoading = ref(false)
const errorMessage = ref('')

const resetPassword = async () => {
  if (!email.value) {
    errorMessage.value = '请输入邮箱地址'
    return
  }

  isLoading.value = true
  errorMessage.value = ''

  try {
    await api.resetPassword(email.value)
    resetSent.value = true
  } catch (error) {
    console.error('发送重置密码邮件失败', error)
    errorMessage.value = error.response?.data?.message || '发送失败，请重试'
  } finally {
    isLoading.value = false
  }
}
</script>

<style scoped>
.success-message {
  color: green;
}
.error-message {
  color: red;
}
</style>
