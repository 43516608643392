<template>

    <div class="search-container">
        <h1 class="search-title">产品开发难？试试店铺选品，快速找到对标店铺，轻松破局!</h1>
        <!-- 搜索表单 -->
        <div class="search-form">
            <el-form :model="searchForm" label-width="120px">
                <div class="left-form">
                    <div class="insert-data">
                        <el-form-item>
                            <div class="category-header">
                                <h2>店铺类目</h2>
                                <el-button @click="toggleCheckAll" type="primary" link class="select-all-btn">
                                    {{ isAllSelected ? '取消全选' : '全选' }}
                                </el-button>
                            </div>
                            <div class="category-wrapper">
                                <el-checkbox-group v-model="searchForm.catagorys">
                                    <el-checkbox v-for="item in catagoryOptions" :key="item.value" :value="item.value">
                                        {{ item.label }}
                                    </el-checkbox>
                                </el-checkbox-group>
                            </div>
                        </el-form-item>
                    </div>
                </div>
                <div class="right-form">
                    <div class="insert-data-right">
                        <div class="shuiping">
                            <!-- 添加国家选择下拉框 -->
                            <el-form-item>
                                <el-select v-model="searchForm.country" placeholder="请选择国家">
                                    <el-option v-for="item in countryOptions" :key="item.value" :label="item.label"
                                        :value="item.value" />
                                </el-select>
                            </el-form-item>

                            <!-- 添加地址输入框 -->
                            <el-form-item>
                                <el-input v-model="searchForm.storeAddress" placeholder="请输入地址" />
                            </el-form-item>

                            <!-- 店铺反馈 -->
                            <el-form-item>
                                <el-input v-model="searchForm.minStoreFeedback" placeholder="店铺反馈大于" />
                            </el-form-item>
                            <span class="nokongxi">-</span>
                            <el-form-item>
                                <el-input v-model="searchForm.maxStoreFeedback" placeholder="店铺反馈小于" />
                            </el-form-item>

                            <!-- 店铺评分 -->
                            <el-form-item>
                                <el-input v-model="searchForm.minStoreStar" placeholder="店铺评分大于" />
                            </el-form-item>
                            <span class="nokongxi">-</span>
                            <el-form-item>
                                <el-input v-model="searchForm.maxStoreStar" placeholder="店铺评分小于" />
                            </el-form-item>
                        </div>

                        <div class="shuiping">

                            <!-- 店铺首页月销 -->
                            <el-form-item>
                                <el-input v-model="searchForm.minStoreMonthSales" placeholder="店铺首页月销大于" />
                            </el-form-item>
                            <span class="nokongxi">-</span>
                            <el-form-item>
                                <el-input v-model="searchForm.maxStoreMonthSales" placeholder="店铺首页月销小于" />
                            </el-form-item>

                            <!-- 首页平均评价 -->
                            <el-form-item>
                                <el-input v-model="searchForm.minAvgReview" placeholder="首页平均评价大于" />
                            </el-form-item>
                            <span class="nokongxi">-</span>
                            <el-form-item>
                                <el-input v-model="searchForm.maxAvgReview" placeholder="首页平均评价小于" />
                            </el-form-item>

                            <!-- 首页平均价格 -->
                            <el-form-item>
                                <el-input v-model="searchForm.minStoreAVPrice" placeholder="首页平均价格大于" />
                            </el-form-item>
                            <span class="nokongxi">-</span>
                            <el-form-item>
                                <el-input v-model="searchForm.maxStoreAVPrice" placeholder="首页平均价格小于" />
                            </el-form-item>
                        </div>

                        <div class="shuiping">

                            <!-- 店铺产品数量 -->
                            <el-form-item>
                                <el-input v-model="searchForm.minStoreResult" placeholder="店铺产品数量大于" />
                            </el-form-item>
                            <span class="nokongxi">-</span>
                            <el-form-item>
                                <el-input v-model="searchForm.maxStoreResult" placeholder="店铺产品数量小于" />
                            </el-form-item>

                            <!-- 首页自发货比率 -->
                            <el-form-item>
                                <el-input v-model="searchForm.minStoreAVFBM" placeholder="自发货比率大于" />
                            </el-form-item>
                            <span class="nokongxi">-</span>
                            <el-form-item>
                                <el-input v-model="searchForm.maxStoreAVFBM" placeholder="自发货比率小于" />
                            </el-form-item>

                        </div>

                        <div class="storeline">------------------------------ 店铺产品查询 ------------------------------
                        </div>

                        <div class="shuiping">

                            <!-- 产品价格 -->
                            <el-form-item>
                                <el-input v-model="searchForm.minPrice" placeholder="产品价格大于" />
                            </el-form-item>
                            <span class="nokongxi">-</span>
                            <el-form-item>
                                <el-input v-model="searchForm.maxPrice" placeholder="产品价格小于" />
                            </el-form-item>

                            <!-- 产品评价 -->
                            <el-form-item>
                                <el-input v-model="searchForm.minReview" placeholder="产品评价大于" />
                            </el-form-item>
                            <span class="nokongxi">-</span>
                            <el-form-item>
                                <el-input v-model="searchForm.maxReview" placeholder="产品评价小于" />
                            </el-form-item>

                            <!-- 店铺首页月销 -->
                            <el-form-item>
                                <el-input v-model="searchForm.minRatingScore" placeholder="产品评分大于" />
                            </el-form-item>
                            <span class="nokongxi">-</span>
                            <el-form-item>
                                <el-input v-model="searchForm.maxRatingScore" placeholder="产品评分小于" />
                            </el-form-item>

                        </div>

                        <div class="shuiping">

                            <!-- 店铺产品数量 -->
                            <el-form-item>
                                <el-input v-model="searchForm.minBigCategoryRank" placeholder="大类排名大于" />
                            </el-form-item>
                            <span class="nokongxi">-</span>
                            <el-form-item>
                                <el-input v-model="searchForm.maxBigCategoryRank" placeholder="大类排名小于" />
                            </el-form-item>

                            <!-- 产品月销 -->
                            <el-form-item>
                                <el-input v-model="searchForm.minMonthSales" placeholder="子体月销大于" />
                            </el-form-item>
                            <span class="nokongxi">-</span>
                            <el-form-item>
                                <el-input v-model="searchForm.maxMonthSales" placeholder="子体月销小于" />
                            </el-form-item>

                            <!-- 产品发货模式 -->
                            <el-form-item>
                                <el-select v-model="searchForm.fbam" placeholder="发货模式">
                                    <el-option v-for="item in fbamOptions" :key="item.value" :label="item.label"
                                        :value="item.value" />
                                </el-select>
                            </el-form-item>

                            <!-- 上架时间 -->
                            <el-form-item>
                                <el-select v-model="searchForm.alltime" placeholder="上架时间" filterable allow-create
                                    :filter-method="filterTime" @create="handleTimeCreate">
                                    <el-option v-for="item in alltimeOptions" :key="item.value" :label="item.label"
                                        :value="item.value" />
                                </el-select>
                            </el-form-item>

                        </div>

                        <div class="shuiping store-track">
                            <el-form-item style="width: 100% !important;">
                                <el-input v-model="searchForm.sellerid" placeholder="店铺追踪,多个店铺ID用英文逗号分隔" />
                            </el-form-item>
                        </div>

                        <div class="shuiping">

                            <!-- 产品重量 -->
                            <el-form-item>
                                <el-input v-model="searchForm.minItemWeight" placeholder="商品重量大于" />
                            </el-form-item>
                            <span class="nokongxi">-</span>
                            <el-form-item>
                                <el-input v-model="searchForm.maxItemWeight" placeholder="商品重量小于" />
                            </el-form-item>

                            <el-form-item>
                                <el-checkbox v-model="searchForm.onlyOne" class="sort-checkbox">
                                    产品有变体时只显示一个变体
                                </el-checkbox>
                            </el-form-item>

                        </div>

                        <div class="shuiping">

                            <!-- 产品评价 -->
                            <el-form-item class="button-group-search">
                                <el-button type="primary" @click="handleSearch" :loading="loading"
                                    style="width: 100px;">
                                    查询
                                </el-button>
                                <el-button @click="resetForm">
                                    重置
                                </el-button>

                                <el-button @click="openTutorial">
                                    <el-icon class="icon">
                                        <VideoCamera />
                                    </el-icon>
                                    <span style="margin-left: 5px;">使用视频教程</span>
                                </el-button>
                                <span class="icon-text">试试店铺选品, 快速找到对标店铺, 轻松破局!</span>
                            </el-form-item>

                        </div>



                    </div>
                </div>

            </el-form>
        </div>

        <!-- 搜索结果 -->
        <div class="search-results" v-loading="loading">

            <div v-show="products.length" class="product-list">
                <div v-for="product in products" :key="product.ASIN" class="product-card">

                    <div class="detail">

                        <div class="detailleft">
                            <div class="product-image">
                                <img :src="product.ImgSrc" alt="Product Image" style="width: 180px; height: 180px;" />
                            </div>
                        </div>

                        <div class="detailright">
                            <div class="product-title">{{ product.Title }}</div>
                            <div class="product-detail-info">
                                <span class="product-asin">ASIN:</span>
                                <span class="product-asin-value">{{ product.ASIN }}</span>
                                <span class="product-begintime">上架时间: {{ formatDate(product.BeginTime) }}</span>
                                <span class="product-price">价格: {{ product.Price?.toFixed(2) || '-' }}</span>
                                <span class="product-review">评论数: {{ product.Review }}</span>
                                <span class="product-reviewstar">评分: {{ product.ReviewStar?.toFixed(1) || '-' }}</span>



                                <div class="product-store-info">
                                    <div class="storeinfo-up">
                                        <span>品牌: {{ product.brand }}</span>
                                        <span>父体月销: {{ product.Sales }}</span>
                                        <span>子体月销: {{ product.MonthSales }}</span>
                                        <span>发货模式: {{ product.FBAM }}</span>
                                        <span v-if="product.Weight > 0">产品重量: {{ product.Weight }}g</span>
                                    </div>
                                    <div class="storeinfo-down">
                                        <span>类目: {{ product.CatagorysSmall }}</span>
                                    </div>

                                    <div class="product-rank-chart-title">
                                        <div class="product-rank-chart-left">
                                            <div class="product-rank-chart">
                                                <RankChart :daily-ranks="parseRankData(product.MonthRank)" />
                                            </div>
                                        </div>
                                        <div class="product-rank-chart-right">
                                            <span><-大类排名曲线 </span>
                                                    <span v-if="product.FBAFee > 0">FBA费用: ${{ product.FBAFee.toFixed(2)
                                                        }}</span>
                                                    <!-- <span v-if="product.Genmai > 1">跟卖数量: {{ product.Genmai }}</span> -->
                                                    <span v-if="product.MaoLi > 0">毛利: {{ product.MaoLi.toFixed(2)
                                                        }}%</span>
                                                    <span v-if="product.BiantiNum > 0">变体数量: {{ product.BiantiNum
                                                        }}</span>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div class="product-button">
                                <!-- <span><a :href="`https://www.amazon.com/s?me=${product.ASIN}`" target="_blank">历史销量</a></span> -->
                                <span><a :href="`${product.Url}`" target="_blank">产品详情</a></span>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <!-- 添加分页组件 -->
            <div class="pagination-container" v-if="products.length">
                <el-pagination 
                    v-model:current-page="currentPage" 
                    v-model:page-size="pageSize" 
                    :page-sizes="[30, 50]"
                    :prev-text="'上一页'"
                    :next-text="'下一页'"
                    layout="prev, next" 
                    :total="total" 
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange" />
            </div>

            <el-empty v-else-if="!loading && !hasSearched" description="请输入搜索条件" />
            <el-empty v-else-if="!loading && hasSearched" description="暂无符合条件的数据" />
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, computed } from 'vue'
import { ElMessage } from 'element-plus'
import api from '@/services/api'
import { debounce } from 'lodash-es'
import RankChart from './RankChart.vue'
import { VideoCamera } from '@element-plus/icons-vue'  // 添加图标导入

const loading = ref(false)
const products = ref([])
const currentPage = ref(1)
const pageSize = ref(50)
const total = ref(0)
const hasSearched = ref(false)

// 修改 searchForm，添加分页参数
const searchForm = reactive({
    catagorys: [],
    country: 'all',
    storeAddress: '',
    minStoreFeedback: '',
    maxStoreFeedback: '',
    minStoreStar: '',
    maxStoreStar: '',

    minStoreMonthSales: '',
    maxStoreMonthSales: '',

    minAvgReview: '',
    maxAvgReview: '',
    minStoreAVPrice: '',
    maxStoreAVPrice: '',
    minStoreResult: '',
    maxStoreResult: '',
    minStoreAVFBM: '',
    maxStoreAVFBM: '',

    // 以下为产品
    minBigCategoryRank: '',
    maxBigCategoryRank: '',
    minReviewStar: '',
    maxReviewStar: '',
    minReview: '',
    maxReview: '',
    minPrice: '',
    maxPrice: '',
    minRatingScore: '',
    maxRatingScore: '',
    minMonthSales: '',
    maxMonthSales: '',
    fbam: '',
    alltime: '',
    onlyOne: true,
    page: 1,
    pageSize: 50,
})

const catagoryOptions = [
    { value: 'Electronics', label: '电子产品 : Electronics' },
    { value: 'Clothing', label: '服装 : Clothing, Shoes & Jewelry' },
    { value: 'Home', label: '家居用品 : Home & Kitchen' },
    { value: 'Toys', label: '玩具 : Toys & Games' },
    { value: 'Arts', label: '艺术 : Arts, Crafts & Sewing' },
    { value: 'Automotive', label: '汽配 : Automotive' },
    { value: 'Baby', label: '母婴 : Baby' },
    { value: 'Beauty', label: '美妆 : Beauty & Personal Care' },
    { value: 'Computer', label: '电脑 : Computers & Accessories' },
    { value: 'Appliances', label: '电器 : Appliances' },
    { value: 'Camera', label: '相机摄影 : Camera & Photo' },
    { value: 'Cell', label: '手机通讯 : Cell Phones & Accessories' },
    { value: 'Collectibles', label: '收藏品 : Collectibles' },
    { value: 'Dining', label: '餐饮用品 : Dining' },
    { value: 'Grocery', label: '食品杂货 : Grocery & Gourmet Food' },
    { value: 'Handmade', label: '手工制品 : Handmade Products' },
    { value: 'Health', label: '健康医疗 : Health & Personal Care' },
    { value: 'Industrial', label: '工业用品 : Industrial & Scientific' },
    { value: 'Musical', label: '乐器音乐 : Musical Instruments' },
    { value: 'Office', label: '办公用品 : Office Products' },
    { value: 'Outdoors', label: '户外用品 : Outdoor Recreation' },
    { value: 'Patio', label: '庭院用品 : Patio, Lawn & Garden' },
    { value: 'Pet', label: '宠物用品 : Pet Supplies' },
    { value: 'Tools', label: '工具 : Tools & Home Improvement' }
]

// 添加国家选项
const countryOptions = [
    { value: 'all', label: '所有国家' },
    { value: 'US', label: '美国' },
    { value: 'CN', label: '中国' }
]

const fbamOptions = [
    { value: 'all', label: '所有发货模式' },
    { value: 'FBA', label: 'FBA' },
    { value: 'FBM', label: 'FBM' }
]

const alltimeOptions = [
    { value: 'all', label: '上架时间' },
    { value: '90', label: '最近90天' },
    { value: '180', label: '最近180天' },
    { value: '365', label: '最近1年' },
]

const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
}

// 添加计算属性判断是否全选
const isAllSelected = computed(() => {
    return searchForm.catagorys.length === catagoryOptions.length
})

// 切换全选/取消全选
const toggleCheckAll = () => {
    if (isAllSelected.value) {
        // 如果已经全选，则取消全选
        searchForm.catagorys = []
    } else {
        // 如果未全选，则全选
        searchForm.catagorys = catagoryOptions.map(item => item.value)
    }
}

// 修改搜索方法，增加 isNewSearch 参数
const handleSearch = debounce(async (isNewSearch = true) => {
    try {
        const token = localStorage.getItem('token')
        if (!token || token === 'null') {
            ElMessage.warning({
                message: '请先登录或注册后再进行查询',
                type: 'warning',
                duration: 3000,
                showClose: true,
                center: true
            })
            return
        }

        loading.value = true

        // 只有新搜索时重置页码
        if (isNewSearch) {
            currentPage.value = 1
        }

        // 转换参数类型
        const params = {
            // ...searchForm,
            page: currentPage.value || 1,
            pageSize: pageSize.value || 50,
            // page_size: pageSize.value || 50,
            country: 1,
            storeCountry: searchForm.country,
            // 转换数值类型，保持与后端API一致的参数命名
            storeAddress: searchForm.storeAddress,
            catagorys: searchForm.catagorys,
            minStoreFeedback: parseInt(searchForm.minStoreFeedback) || undefined,
            maxStoreFeedback: parseInt(searchForm.maxStoreFeedback) || undefined,
            minStoreStar: parseInt(searchForm.minStoreStar) || undefined,
            maxStoreStar: parseInt(searchForm.maxStoreStar) || undefined,
            minStoreMonthSales: parseInt(searchForm.minStoreMonthSales) || undefined,
            maxStoreMonthSales: parseInt(searchForm.maxStoreMonthSales) || undefined,
            minAvgReview: parseInt(searchForm.minAvgReview) || undefined,
            maxAvgReview: parseInt(searchForm.maxAvgReview) || undefined,
            minStoreAVPrice: parseFloat(searchForm.minStoreAVPrice) || undefined,
            maxStoreAVPrice: parseFloat(searchForm.maxStoreAVPrice) || undefined,
            minStoreResult: parseInt(searchForm.minStoreResult) || undefined,
            maxStoreResult: parseInt(searchForm.maxStoreResult) || undefined,
            minStoreAVFBM: parseInt(searchForm.minStoreAVFBM) || undefined,
            maxStoreAVFBM: parseInt(searchForm.maxStoreAVFBM) || undefined,
            minMonthSales: parseInt(searchForm.minMonthSales) || undefined,
            maxMonthSales: parseInt(searchForm.maxMonthSales) || undefined,
            minPrice: parseFloat(searchForm.minPrice) || undefined,
            maxPrice: parseFloat(searchForm.maxPrice) || undefined,
            minReview: parseInt(searchForm.minReview) || undefined,
            maxReview: parseInt(searchForm.maxReview) || undefined,
            minRatingScore: parseFloat(searchForm.minRatingScore) || undefined,
            maxRatingScore: parseFloat(searchForm.maxRatingScore) || undefined,
            minBigCategoryRank: parseInt(searchForm.minBigCategoryRank) || undefined,
            maxBigCategoryRank: parseInt(searchForm.maxBigCategoryRank) || undefined,
            fbam: searchForm.fbam,
            alltime: parseInt(searchForm.alltime) || undefined,
            onlyOne: searchForm.onlyOne,
            // 添加额外的参数
            sellerids: searchForm.sellerid ? searchForm.sellerid.split(',') : [],
            minItemWeight: parseInt(searchForm.minItemWeight) || undefined,
            maxItemWeight: parseInt(searchForm.maxItemWeight) || undefined,
        }

        // 清理空值
        Object.keys(params).forEach(key => {
            if (params[key] === undefined ||
                params[key] === '' ||
                (Array.isArray(params[key]) && params[key].length === 0)) {
                delete params[key];
            }
        });

        const res = await api.searchStoreRealTime(params)
        // console.log('搜索结果:', res)
        if (res.code === 200) {
            // 添加二次筛选逻辑
            let filteredProducts = res.data || []

            // 如果设置了最小价格，筛选出价格大于等于最小价格的商品
            if (searchForm.minPrice) {
                const minPrice = parseFloat(searchForm.minPrice)
                filteredProducts = filteredProducts.filter(product =>
                    product.Price !== null && product.Price !== undefined && product.Price >= minPrice
                )
            }

            // 如果设置了最大价格，筛选出价格小于等于最大价格的商品
            if (searchForm.maxPrice) {
                const maxPrice = parseFloat(searchForm.maxPrice)
                filteredProducts = filteredProducts.filter(product =>
                    product.Price !== null && product.Price !== undefined && product.Price <= maxPrice
                )
            }

            // 如果设置了最小评论数，筛选出评论数大于等于最小评论数的商品
            if (searchForm.minReview) {
                const minReview = parseInt(searchForm.minReview)
                filteredProducts = filteredProducts.filter(product =>
                    product.Review !== null && product.Review !== undefined && product.Review >= minReview
                )
            }

            // 如果设置了最大评论数，筛选出评论数小于等于最大评论数的商品
            if (searchForm.maxReview) {
                const maxReview = parseInt(searchForm.maxReview)
                filteredProducts = filteredProducts.filter(product =>
                    product.Review !== null && product.Review !== undefined && product.Review <= maxReview
                )
            }

            // 如果设置了最小评分，筛选出评分大于等于最小评分的商品
            if (searchForm.minRatingScore) {
                const minRatingScore = parseFloat(searchForm.minRatingScore)
                filteredProducts = filteredProducts.filter(product =>
                    product.ReviewStar !== null && product.ReviewStar !== undefined && product.ReviewStar >= minRatingScore
                )
            }

            // 如果设置了最大评分，筛选出评分小于等于最大评分的商品
            if (searchForm.maxRatingScore) {
                const maxRatingScore = parseFloat(searchForm.maxRatingScore)
                filteredProducts = filteredProducts.filter(product =>
                    product.ReviewStar !== null && product.ReviewStar !== undefined && product.ReviewStar <= maxRatingScore
                )
            }

            products.value = filteredProducts  // 更新筛选后的商品数组
            total.value = res.total || 0  // 保持原有的总数不变
            hasSearched.value = true
        } else {
            ElMessage.error(res.msg || '搜索失败')
        }
    } catch (error) {
        if (error.message === 'unauthorized') {
            ElMessage.warning({
                message: '请先登录或注册后再进行查询',
                type: 'warning',
                duration: 3000,
                showClose: true,
                center: true
            })
        } else {
            console.error('搜索失败:', error)
            ElMessage.error('系统错误，请稍后重试')
        }
    } finally {
        loading.value = false
    }
}, 500)

// 修改分页处理方法
const handleCurrentChange = debounce(async (val) => {
    currentPage.value = val
    await handleSearch(false)  // 传递 false 表示分页操作
    
    // 添加滚动逻辑
    const buttonGroup = document.querySelector('.button-group-search')
    if (buttonGroup) {
        const rect = buttonGroup.getBoundingClientRect()
        const targetY = window.scrollY + rect.top - 100  // 修改这里，使用 rect.top 并减去 80px
        window.scrollTo({
            top: targetY,
            behavior: 'smooth'  // 使用平滑滚动效果
        })
    }
}, 200)

const handleSizeChange = debounce(async (val) => {
    pageSize.value = val
    currentPage.value = 1
    await handleSearch(false)  // 传递 false 表示分页操作
}, 200)

// 修改重置方法
const resetForm = () => {
    Object.assign(searchForm, {
        catagorys: [],
        country: 'all',
        storeAddress: '',
        minStoreFeedback: '',
        maxStoreFeedback: '',
        minStoreStar: '',
        maxStoreStar: '',
        minStoreMonthSales: '',
        maxStoreMonthSales: '',
        minAvgReview: '',
        maxAvgReview: '',
        minStoreAVPrice: '',
        maxStoreAVPrice: '',
        minStoreResult: '',
        maxStoreResult: '',
        minStoreAVFBM: '',
        maxStoreAVFBM: '',
        // 产品参数
        minBigCategoryRank: '',
        maxBigCategoryRank: '',
        minMonthSales: '',
        maxMonthSales: '',
        minReview: '',
        maxReview: '',
        minPrice: '',
        maxPrice: '',
        // 添加产品评分重置
        minRatingScore: '',
        maxRatingScore: '',
        fbam: '',
        alltime: '',
        onlyOne: true,
        page: 1,
        pageSize: 50,
    })
    currentPage.value = 1
    pageSize.value = 50
    total.value = 0
    products.value = []
    hasSearched.value = false
}

const parseRankData = (rankStr) => {
    try {
        const data = JSON.parse(rankStr || '[]')
        // 转换为 RankChart 需要的格式
        return {
            'main': data.map(item => ({
                date: item.date,  // 注意这里是小写的 date
                avgRank: item.rank  // 注意这里是小写的 rank
            }))
        }
    } catch (e) {
        console.error('解析排名数据失败:', e)
        return {}
    }
}

const openTutorial = () => {
    window.open('https://mp.weixin.qq.com/s/pJX3STHdZFjd8jysaEjp8Q', '_blank')
}
</script>

<style scoped>
.search-title {
    margin: 0;
    /* 移除默认外边距 */
    margin-top: 60px;
    padding: 35px;
    /* 添加内边距来控制文字与边缘的距离 */
    font-size: 20px;
    font-weight: 700;
    background-color: #fdf6ec;
    color: #606266;
    text-align: center;
    /* 文字居 */
    width: 100%;
    /* 确保宽度占满 */
    box-sizing: border-box;
    /* 确保padding不会增加总宽度 */
}

.search-container {
    margin: 0;
    padding: 0;
    width: 100%;
}

.search-form {
    max-width: 1200px;
    margin: 20px auto;
    padding: 15px;
    padding-bottom: 0;
    background-color: #f0f8ff;
    border-radius: 8px;
}

.search-results {
    max-width: 1200px;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 8px;
    margin: 0 auto;
    margin-bottom: 20px;
}

:deep(.el-form) {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 0;
}

.left-form {
    width: calc(25% - 10px);
    flex-shrink: 0;
}

.right-form {
    width: calc(75% - 10px);
    flex-shrink: 0;
    background-color: #f0f8ff;
}

.insert-data {
    background-color: #ffffff;
    border-radius: 8px;
    padding-top: 15px;
}

/* 美化滚动条样式 */
/* .insert-data::-webkit-scrollbar {
    width: 6px;
  }
  
  .insert-data::-webkit-scrollbar-thumb {
    background-color: #dcdfe6;
    border-radius: 3px;
  }
  
  .insert-data::-webkit-scrollbar-track {
    background: transparent;
  } */

/* 确保内容不会被滚动条遮挡 */
.left-form :deep(.el-form-item__content) {
    margin-left: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 3px;
    padding-right: 15px;
    padding-bottom: 10px;
}

.separator {
    margin: 0 10px;
}

.category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 0 15px; */
    padding-left: 15px;
    width: 100%;
    box-sizing: border-box;
}

.category-header h2 {
    margin: 0;
    padding-left: 9px;
    font-size: 16px;
    color: #606266;
}

.select-all-btn {
    padding: 0;
}

/* 类目列表容器 */
.category-wrapper {
    padding: 0 15px;
    width: 100%;
    box-sizing: border-box;
    height: 316px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    /* Firefox */
    scrollbar-color: #dcdfe6 transparent;
    /* Firefox */
}

.category-wrapper::-webkit-scrollbar {
    width: 6px;
}

.category-wrapper::-webkit-scrollbar-thumb {
    background-color: #dcdfe6;
    border-radius: 3px;
}

.category-wrapper::-webkit-scrollbar-track {
    background: transparent;
}

/* 复选框组样式 */
.category-wrapper :deep(.el-checkbox-group) {
    display: flex;
    flex-direction: column;
    gap: 0;
    width: 100%;
}

/* 单个复选框样式 */
.category-wrapper :deep(.el-checkbox) {
    margin: 0;
    height: 32px;
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 0 8px;
}

/* 鼠标悬停效果 */
.category-wrapper :deep(.el-checkbox):hover {
    background-color: #f5f7fa;
    border-radius: 4px;
}


/* 右侧表单样式 -------------------------------------------*/
.insert-data-right {
    /* background-color: #ffffff; */
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    border-radius: 8px;
}

/* 统一所有表单项的宽度 */
.insert-data-right :deep(.el-form-item) {
    margin: 0 !important;
    width: calc((100% - 75px) / 6) !important;
    /* 这个规则限制了所有 el-form-item 的宽度 */
    box-sizing: border-box;
}

/* 确保表单项内容对齐 */
.insert-data-right :deep(.el-form-item__content) {
    margin-left: 0 !important;
    width: 100%;
}

/* 控制输入框组件的宽度 */
.insert-data-right :deep(.el-select),
.insert-data-right :deep(.el-input),
.insert-data-right :deep(.el-input-number) {
    width: 100%;
}

/* 添加新样式处理 nokongxi */
.nokongxi {
    margin: 0 -11px;
}

.storeline {
    width: 100%;
    text-align: center;
    padding: 10px 0;
    font-size: 12px;
    margin-top: -5px;
    margin-bottom: -8px;
    color: #606266;
}

/* 店铺信息 ++++++++++++++++++++++++++++++++++++++++++++++++++++ */
.shuiping {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    gap: 15px;
    padding: 0;
    flex-wrap: wrap;
}

.shuiping :deep(.el-form-item) {
    margin: 0;
}

/* 确保表单项内的输入框占满整个空间 */
.shuiping :deep(.el-form-item__content) {
    margin-left: 0 !important;
    width: 100%;
}

.shuiping :deep(.el-select),
.shuiping :deep(.el-input) {
    width: 100%;
}

/* 添加分页容器样式 */
.pagination-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    background-color: #ffffff;
    border-radius: 4px;
}

.shuiping :deep(.button-group-search) {
    width: auto !important;
}

.detail {
    display: flex;
    gap: 20px;
    width: 100% !important;
    height: 215px;
    margin-bottom: 15px;

}

.detailleft {
    width: 20% !important;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 8px;
}

.detailright {
    width: 80%;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 15px;
    color: #333333;
    font-size: 14px;
    position: relative;
    min-height: 150px;
}

.product-image {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
}

.product-image img {
    max-width: calc(100% - 20px);
    /* 减去左右各10px的空隙 */
    max-height: calc(100% - 20px);
    /* 减去上下各10px的空隙 */
    object-fit: contain;
}

/* 产品信息 */
.product-title {
    font-size: 14px;
    font-weight: 700;
    color: #606266;
    width: 90%;
    height: 50px;
}

.product-asin {
    border-radius: 3px;
    background-color: #f90;
    color: #fff;
    font-size: 12px;
    padding: 5px 10px;
    margin-right: 10px;
}

.product-asin-value {
    color: #f90;
    font-size: 14px;
    margin-right: 10px;
}

.product-price {
    padding: 0 10px;
}

.product-review {
    padding: 0 10px;
}

.product-reviewstar {
    padding: 0 10px;
}

.product-monthsales {
    padding: 0 10px;
}

.product-begintime {
    padding: 0 10px;
}

.product-store {
    border-radius: 3px;
    background-color: #a0cfff;
    color: #fff;
    font-size: 12px;
    padding: 5px 10px;
    margin-right: 10px;
}

.product-store a {
    color: #333333;
}

.product-store-info {
    font-size: 12px;
    border-radius: 5px;
    margin-top: 20px;
    background-color: #f5f5f5;
    padding: 10px;
}

.product-store-info div.storeinfo-up {
    padding-bottom: 10px;
}

.product-store-info div span {
    padding-right: 15px;
}

.product-button {
    position: absolute;
    bottom: 15px;
    right: 15px;
    padding: 5px 5px;
    background-color: #f90;
    color: #ffffff;
    border-radius: 4px 0 4px 0;
    /* cursor: pointer; */
}

.product-button span {
    padding: 0 10px;
}

.product-button span a {
    color: #ffffff;
    font-size: 13px;
}

.storeinfo-product {
    margin-top: 10px;
}

.product-rank-chart {
    margin-top: 10px;
}

/* .product-button:hover {
    background-color: #66b1ff;
  } */

.product-rank-chart-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.product-rank-chart-left {
    width: 30%;
    padding-right: 10px;
    border-radius: 8px;
}

.product-rank-chart-right {
    width: 70%;
    padding-left: 10px;
    color: #606266;
    font-size: 12px;
}

/* 修改原有的 .product-rank-chart 样式 */
.product-rank-chart {
    margin-top: 0;
    /* 移除原有的上边距 */
    width: 100%;
    /* 确保图表占满左侧区域 */
}

.shuiping .icon-text {
    color: red;
    margin-left: 15px;
}
</style>