import axios from 'axios'
import { ElMessage } from 'element-plus'

const api = axios.create({
    baseURL: 'https://www.topai123.com/api/',
    // baseURL: 'http://localhost:8000/api/',
    timeout: 15000,
    headers: {
        'Content-Type': 'application/json'
    },
    withCredentials: true
})

// 添加请求拦截器
api.interceptors.request.use(config => {
    return config
})

// 添加响应拦截器
api.interceptors.response.use(response => {
    return response
}, error => {
    return Promise.reject(error)
})

// -----------vipapi拦截器-----------

const vipapi = axios.create({
    baseURL: 'https://www.topai123.com/api/',
    // baseURL: 'http://localhost:8000/api/',
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    withCredentials: true
})

// 请求拦截器
vipapi.interceptors.request.use(config => {
    const token = localStorage.getItem('token');

    if (token && token !== 'null') {
        config.headers = {
            ...config.headers,
            'Authorization': `Bearer ${token}`
        };
        return config;
    } else {
        return Promise.reject(new Error('请先登录'));
    }
}, error => {
    return Promise.reject(error);
});

// 添加响应拦截器
vipapi.interceptors.response.use(response => {
    if (response.data.code === 4012) {
        // 清除所有本地存储
        localStorage.removeItem('token')
        localStorage.removeItem('refresh')
        localStorage.removeItem('refresh_token_expiry')
        localStorage.removeItem('last_login')
        ElMessage.error(response.data.message)

        // 强制刷新页面并跳转到登录页
        window.location.href = '/login'

        return Promise.reject(new Error(response.data.message))
    }

    return response;
}, error => {
    // 处理请求拦截器抛出的登录错误
    if (error.message === '请先登录') {
        ElMessage.error('请先登录');
        window.location.href = '/login';
    } else {
        // 处理其他错误
        ElMessage.error(error.message || '请求失败');
    }
    return Promise.reject(error);
});

export default {
    register(data) {
        // 直接发送数据对象，让 axios 来处理序列化
        return api.post('register', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    },
    login(data) {
        // 确保data包含验证码相关字段
        const loginData = {
            email: data.email,
            password: data.password,
            captcha_id: data.captcha_id,
            captcha_code: data.captcha_code
        }
        return api.post('login', loginData, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.data.code === 2000 && response.data.data?.tokens) {
                localStorage.setItem('token', response.data.data.tokens.access_token);
                localStorage.setItem('refresh', response.data.data.tokens.refresh_token);
            }
            return response;
        });
    },

    forgotPassword(email) {
        return api.post('forgot', { email })
    },

    resetPasswordConfirm(data) {
        return api.post('reset', data)
    },

    searchAsin(data) {
        const token = localStorage.getItem('token');
        if (!token || token === 'null') {
            window.location.href = '/login';
            return Promise.reject(new Error('请先登录'));
        }

        return vipapi.post('search', data, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
    },

    getCaptcha() {
        return api.get('captcha').then(response => {
            if (response.data.data) {
                const { image_base64, ...rest } = response.data.data;
                response.data.data = {
                    ...rest,
                    imageBase64: image_base64
                };
            }
            return response;
        });
    },

    getUserProfile() {
        return vipapi.get('userinfo').catch(error => {
            if (error.message === '请先登录') {
                window.location.href = '/login';
            }
            return Promise.reject(error);
        });
    },

    setupSearchSSE(params) {
        const token = localStorage.getItem('token');
        if (!token || token === 'null') {
            window.location.href = '/login';
            return null;
        }

        // 构建查询参数
        const queryString = new URLSearchParams({
            ...params,
            token: token
        }).toString();

        // 创建 EventSource，禁用自动重连
        const eventSource = new EventSource(
            // `http://localhost:8000/api/search/stream?${queryString}`,
            `https://www.topai123.com/api/search/stream?${queryString}`,
            {
                withCredentials: true
            }
        );

        let reconnectAttempts = 0;
        const MAX_RECONNECT_ATTEMPTS = 3;

        // 添加事件监听器
        eventSource.addEventListener('open', () => {
            // console.log('SSE 连接已建立');
        });

        eventSource.addEventListener('error', (error) => {
            // console.error('SSE 连接错误:', error);
            if (eventSource.readyState === EventSource.CLOSED) {
                // console.log('SSE 连接已关闭');
                eventSource.close();
            }
        });

        // 添加心跳处理
        eventSource.addEventListener('heartbeat', (e) => {
            // console.log('收到心跳信号:', e.data);
        });

        // 添加完成事件处理
        eventSource.addEventListener('complete', (e) => {
            // console.log('数据更新完成:', e.data);
            eventSource.close();
        });

        return eventSource;
    },

    // 获取Bond999统计数据
    getBond999() {
        return vipapi.get('bond999').catch(error => {
            if (error.message === '请先登录') {
                window.location.href = '/login';
            }
            return Promise.reject(error);
        });
    },

    async searchNewProducts(params) {
        try {
            const response = await vipapi.post('newproduct', params)
            return response.data
        } catch (error) {
            throw new Error(error.response?.data?.message || '搜索失败')
        }
    },

    async searchStoreRealTime(params) {
        try {
            const response = await vipapi.post('storeproductapi', params)
            return response.data
        } catch (error) {
            throw new Error(error.response?.data?.message || '搜索失败')
        }
    },

    get: (url) => api.get(url),
    post: (url, data) => api.post(url, data),
    put: (url, data) => api.put(url, data),
    delete: (url) => api.delete(url),

    // 替换 SSE 方法为普通 POST 请求
    searchStoreProducts: (params) => {
        return vipapi.post('/storeproduct', params)  // 使用 vipapi 而不是 api
            .then(response => response.data)
            .catch(error => {
                if (error.message === '请先登录') {
                    return Promise.reject(new Error('unauthorized'))
                }
                return Promise.reject(error)
            })
    }
}