<template>
  <div class="user-profile-container">
    <div class="h1title">
      <h1 class="search-title-h1">用户中心 - 智能锁定蓝海</h1>
    </div>
    <div v-if="isLoading">加载中...</div>
    <div v-else-if="user" class="user-content">
      <div class="left">
        <h3>TOPAI123官方客服</h3>
        <p class="customer-service-text">联系电话: 137 1157 9919</p>
        <div class="qrcode-item">
            <img :src="require('@/assets/images/weixin1.png')" width="100%" alt="扫码获取专业服务" />
          </div>
        <p class="customer-service-text">
          <span>尊敬的客户您好！</span>
          感谢使用我们的智能选品系统!系统已实现亚马逊全球8大站点数据24小时实时更新, 确保您获取最新市场动态与竞品情报。
          独创的智能选品引擎支持多维度店铺数据分析, 快速定位蓝海品产品。欢迎随时联系在线客服为您解答!
        </p>
      </div>
      <div class="right">
        <div class="user-info-item">
          <table>
            <tbody>
              <tr>
                  <td>用户权限：</td>
                  <td>{{ user.role === 6 ? '免费用户' : getRoleName(user.role) }}</td>
                  <td>邮箱：</td>
                  <td>{{ user.email }}</td>
                  <td>电话号码：</td>
                  <td>{{ user.phone || '未设置' }}</td>
              </tr>
              <tr>
                  <td>积分：</td>
                  <td>{{ user.points }}</td>
                  <td>邀请码：</td>
                  <td>{{ user.invite_code }}</td>
                  <td>{{ user.role === 6 ? '升级为VIP用户' : '尊敬的VIP用户' }}</td>
                  <td>客服微信 team315</td>
              </tr>
            </tbody>
          </table>

          <table>
            <tbody>
              <tr>
                  <td width="300px">TOPAI123选品工具使用视频教程:</td>
                  <td><a href="https://mp.weixin.qq.com/s/z76JYOg13Rzv34YoA2u3fw" target="_blank">点击观看</a></td>
              </tr>
            </tbody>
          </table>
        </div>
        <p class="system-features-title">各位亚马逊老铁，推新品是不是总遇到这些头疼问题？ACOS长期徘徊60%+，广告单占比过半，钱烧得肉疼还不赚钱！这烧钱黑洞我们踩过太多坑了！</p>
        <p class="system-features-title">说句掏心窝的，选品才是破局关键！我们实操发现：花2周精挑细选出来的神仙产品，运营能省半年劲！这三个选品秘籍亲测有效：</p>
        <p class="system-features-title">1. 竞品店铺监控：盯着类目TOP50店铺的新动作，黑马产品刚冒头就跟上</p>
        <p class="system-features-title">2. 蓝海词挖掘：用长尾关键词工具抓细分需求，避开红海厮杀</p>
        <p class="system-features-title">3. 新品库活用：我们自研的实时选品系统，每天凌晨3点自动抓取平台新爆款</p>
        <p class="system-features-title">最新品库特点</p>
        <p class="system-features-title">1. 实时更新：每天凌晨3点自动抓取平台新爆款</p>
        <p class="system-features-title">2. 数据库实时监控50亿多款产品，各种新品和潜力爆款应有尽有!!</p>
        <p class="system-features-title">3. 可以跟踪任意店铺，实时监控店铺上新，新品上架第一时间抓取!</p>
        <p class="system-features-title">4. 支持多站点，8大站点全覆盖，亚马逊全球数据实时更新!</p>
        <p class="system-features-title">5. 可查看任意类目所有数据，不仅仅只是TOP前100</p>
        <p class="system-features-title">6. 可以查看所有断货产品</p>
        <p class="system-features-title">7. 可以查看任意品牌所有数据!</p>
      </div>
    </div>
    <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import api from '@/services/api'

const user = ref(null)
const isLoading = ref(true)
const errorMessage = ref('')
const router = useRouter()
const resetRequested = ref(false)
const resetCode = ref('')
const newPassword = ref('')
const confirmPassword = ref('')
const isResetting = ref(false)
const resetMessage = ref('')
const resetSuccess = ref(false)

const fetchUserProfile = async () => {
  isLoading.value = true
  errorMessage.value = ''
  try {
    const response = await api.getUserProfile()
    if (response.data.code === 4012) {

    } else if (response.data.code === 4003) {
      ElMessage.error(response.data.message)
    } else {
      user.value = response.data.data
    }
  } catch (error) {
    // 处理错误情况
    if (error.response?.status === 429) {
      errorMessage.value = '请求过于频繁，请稍后再试'
    } else {
      errorMessage.value = '获取用户信息失败，请重试'
    }
  } finally {
    isLoading.value = false
  }
}


const getRoleName = (roleId) => {
  const roles = {
    1: '超级管理员',
    2: 'VIP年用户',
    3: '半年用户',
    4: '季度用户',
    5: '月度用户',
    6: '免费用户'
  }
  return roles[roleId] || '未知角色'
}

onMounted(fetchUserProfile)
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.h1title {
  margin-top: 60px;
  margin-bottom: 30px;
}

.user-content {
  display: flex;
  gap: 20px;
  padding: 0 20px;
  margin-bottom: 20px;
}

.left,
.right {
  background: #fff;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.left {
  width: 25%;
  max-width: 300px;
}


.left h3 {
  margin: 0;
  padding: 5px 10px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid #f8f7f7;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  color: #606266;
}

.left .customer-service-text {
  font-size: 14px;
  color: #606266;
  border-radius: 5px;
  margin: 10px 0;
  margin-bottom: 0;
  padding: 10px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}
.left .qrcode-item{
  margin-top: 15px;
}

.right {
  width: 75%;
}

.search-title-h1 {
  margin: 0;
  padding: 35px;
  font-size: 20px;
  font-weight: 700;
  background-color: #fdf6ec;
  color: #606266;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

.error-message {
  color: red;
}

.success-message {
  color: green;
}

.user-info-item table {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #ebeef5;
  margin: 20px 0;
  margin-top: 0;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.user-info-item td {
  padding: 12px 15px;
  border: 1px solid #ebeef5;
  font-size: 14px;
  color: #606266;
  line-height: 1.5;
}

.user-info-item tr:nth-child(even) {
  background-color: #fafafa;
}

.user-info-item tr:hover {
  background-color: #f5f7fa;
}

.user-info-item td:first-child {
  font-weight: 600;
  color: #909399;
  background-color: #f8f9fc;
}

.user-info-item tr:first-child td:first-child {
  border-top-left-radius: 8px;
}

.user-info-item tr:first-child td:last-child {
  border-top-right-radius: 8px;
}

.user-info-item tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.user-info-item tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

@media (max-width: 768px) {
  .user-content {
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }
  .left,
  .right {
    width: 100%;
  }
  .user-info-item table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
  
  .user-info-item td {
    min-width: 120px;
  }
}
</style>
