<template>
    <div class="chart-container">
      <div ref="chartRef"></div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, onUnmounted, watch, nextTick } from 'vue'
  import * as echarts from 'echarts'
  
  const props = defineProps({
    dailyRanks: {
      type: Object,
      default: () => ({})
    }
  })
  
  const chartRef = ref(null)
  let chart = null
  
  const initChart = async () => {
    await nextTick()
    if (!chartRef.value) return
    
    if (chart) {
      chart.dispose()
    }
    chart = echarts.init(chartRef.value)
  }
  
  const updateChart = () => {
    if (!chart || !props.dailyRanks) return
    
    const categoryIds = Object.keys(props.dailyRanks)
    if (categoryIds.length < 1) return
    
    const mainCategoryData = props.dailyRanks[categoryIds[0]] || []
    
    if (mainCategoryData.length === 0) return
  
    const option = {
      animation: true,
      tooltip: {
        trigger: 'axis',
        formatter: function(params) {
          const date = params[0].axisValue
          const rank = params[0].value
          return `<div style="padding: 3px;">
            <div style="color: #666; font-size: 11px;">${date}</div>
            <div style="color: #333; font-weight: bold; font-size: 12px;">Rank: ${rank}</div>
          </div>`
        },
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderColor: '#eee',
        borderWidth: 1,
        padding: [5, 10],
        textStyle: {
          fontSize: 12
        }
      },
      grid: {
        top: '15%',
        left: '0',
        right: '0',
        bottom: '15%',
        containLabel: false
      },
      xAxis: {
        type: 'category',
        data: mainCategoryData.map(item => item.date),
        show: false,
        boundaryGap: false
      },
      yAxis: {
        type: 'value',
        inverse: true,
        show: false
      },
      series: [
        {
          type: 'line',
          data: mainCategoryData.map(item => item.avgRank),
          smooth: true,
          symbol: 'none',
          lineStyle: {
            width: 2,
            color: '#409EFF'
          },
          itemStyle: {
            color: '#409EFF'
          }
        }
      ]
    }
  
    try {
      chart?.setOption(option, true)
    } catch (e) {
      console.error('图表更新错误:', e)
    }
  }
  
  // 使用 deep watch 监听数据变化
  watch(() => props.dailyRanks, async () => {
    await nextTick()
    if (chartRef.value) {
      if (!chart) {
        await initChart()
      }
      updateChart()
    }
  }, { deep: true, immediate: true })
  
  // 监听窗口大小变化
  const handleResize = () => {
    if (chart) {
      chart.resize()
    }
  }
  
  onMounted(async () => {
    await initChart()
    updateChart()
    window.addEventListener('resize', handleResize)
  })
  
  onUnmounted(() => {
    if (chart) {
      chart.dispose()
      chart = null
    }
    window.removeEventListener('resize', handleResize)
  })
  </script>

<style scoped>
.chart-container {
  width: 100%;
  height: 25px;
}

.chart-container > div {
  width: 100%;
  height: 100%;
}
</style>