<template>
  <div class="page-wrapper">
    <div class="page-container">
      <div class="image-container">
        <img src="@/assets/images/logo.png" alt="logo" class="logo-image">
      </div>
      
      <div class="forgot-password">
        <h2>忘记密码</h2>
        <el-form :model="form" :rules="rules" ref="formRef" label-width="100px">
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="form.email" placeholder="请输入您的邮箱" />
          </el-form-item>

          <el-form-item>
            <div class="button-group">
              <el-button type="primary" @click="handleSubmit" :loading="isLoading">
                {{ isLoading ? '发送中...' : '发送重置链接' }}
              </el-button>
              <div class="links">
                <router-link to="/login" class="link">返回登录</router-link>
                <router-link to="/register" class="link">注册账号</router-link>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { ElMessage } from 'element-plus';
import api from '@/services/api';

const formRef = ref(null);
const isLoading = ref(false);

const form = reactive({
  email: ''
});

const rules = {
  email: [
    { required: true, message: '邮箱必填', trigger: 'blur' },
    { type: 'email', message: '邮箱格式不正确', trigger: ['blur', 'change'] }
  ]
};

const handleSubmit = async () => {
  isLoading.value = true;
  try {
    await formRef.value.validate(); // 验证表单
    // 处理发送重置链接的逻辑
    await api.forgotPassword(form.email);
    ElMessage.success('重置链接已发送到您的邮箱');
  } catch (error) {
    // 验证失败时的处理
    if (error) {
      ElMessage.error('请检查输入的邮箱');
    }
  } finally {
    isLoading.value = false;
  }
};
</script>

<style scoped>
.page-wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: #f5f7fa;
}

.page-container {
  display: flex;
  width: 100%;
  height: auto;
  min-height: 300px;
  max-width: 1080px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.image-container {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f7fa;
  border-radius: 4px 0 0 4px;
  overflow: hidden;
}

.logo-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.forgot-password {
  width: 45%;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 0 4px 4px 0;
}

.forgot-password h2 {
  padding-left: 30px;
  font-size: 24px;
  font-weight: 600;
}

.button-group {
  display: flex;
  align-items: center;
  gap: 15px;
}

.links {
  display: flex;
  gap: 10px;
}

.link {
  font-size: 14px;
  color: var(--el-text-color-regular);
  text-decoration: none;
}

.link:hover {
  color: var(--el-color-primary);
}
</style>