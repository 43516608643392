<template>
  <div class="default-layout">
    <nav class="navbar">
      <div class="logo">
        <router-link to="/">
          <img src="/topai.png" alt="TOPAI123.COM" class="logo-image" />
        </router-link>

        <div class="logo-texts">
          <span class="logo-text1">智能锁定蓝海</span>
          <span class="logo-text2">透视千亿蓝海, AI预判爆款基因</span>
        </div>
      </div>
      <div class="nav-links">
        <router-link class="nav-item" to="/">首页</router-link>
        <router-link class="nav-item" to="/storesearch">店铺选品</router-link>
        <router-link class="nav-item" to="/newproduct">最新产品库</router-link>
        <a class="nav-item" href="https://mp.weixin.qq.com/s/z76JYOg13Rzv34YoA2u3fw" target="_blank">选品教程</a>
        <!-- <router-link class="nav-item" to="https://mp.weixin.qq.com/s/z76JYOg13Rzv34YoA2u3fw">选品教程</router-link> -->
        <router-link v-if="!isAuthenticated" class="nav-item" to="/register">注册</router-link>
        <router-link v-if="!isAuthenticated" class="nav-item" to="/login">登录</router-link>
        <router-link v-if="isAuthenticated" class="nav-item" to="/profile">用户中心</router-link>
        <el-button v-if="isAuthenticated" type="danger" class="logout-button" @click="logout">退出</el-button>
      </div>
    </nav>

    <router-view class="main-content"></router-view>

    <footer class="footer">
      <div class="footer-container">
        <!-- 左侧二维码区域 -->
        <div class="qrcode-section">
          <div class="qrcode-item">
            <img :src="require('@/assets/images/weixin1.png')" alt="扫码获取专业服务" />
            <p>扫码获取专业服务</p>
          </div>
          <div class="qrcode-item">
            <img :src="require('@/assets/images/weixin2.png')" alt="扫码了解最新动态" />
            <p>扫码了解最新动态</p>
          </div>
        </div>

        <!-- 右侧导航链接 -->
        <div class="nav-section">
          <div class="nav-group">
            <h3>产品</h3>
            <ul>
              <li><router-link to="/">店铺选品</router-link></li>
              <li><router-link to="/">最新产品库</router-link></li>
            </ul>
          </div>
          <div class="nav-group">
            <h3>资源</h3>
            <ul>
              <li><router-link to="/">跨境资讯</router-link></li>
              <li><router-link to="/">跨境活动</router-link></li>
            </ul>
          </div>
          <div class="nav-group">
            <h3>解决方案</h3>
            <ul>
              <li><router-link to="/">跨境老炮1对1指导</router-link></li>
              <li><router-link to="/">API数据集成方案</router-link></li>
            </ul>
          </div>
          <div class="nav-group">
            <h3>合作伙伴</h3>
            <ul>
              <li><router-link to="/">期待您的加入</router-link></li>
            </ul>
          </div>
          <div class="nav-group">
            <h3>关于</h3>
            <ul>
              <li><router-link to="/">加入我们</router-link></li>
            </ul>
            <span>联系电话: 137 1157 9919</span>
          </div>
        </div>
      </div>

      <!-- 底部版权信息 -->
      <div class="footer-bottom">
        <div class="copyright">
          <p>版权所有 ©2025 TOPAI123.COM</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()
const isAuthenticated = computed(() => store.getters.isAuthenticated)

// 退出功能
const logout = () => {
  store.commit('logout') // 调用 Vuex 的 logout 方法
  localStorage.removeItem('token') // 清除用户信息
  router.push('/login') // 重定向到登录页面
}
</script>

<style scoped>
.default-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* 确保 footer 在页面底部 */
}

.navbar {
  position: fixed;
  /* 固定导航栏 */
  top: 0;
  /* 距离顶部 0 */
  left: 0;
  /* 距离左侧 0 */
  right: 0;
  /* 距离右侧 0 */
  display: flex;
  justify-content: space-between;
  /* 左右对齐 */
  align-items: center;
  /* 垂直居中 */
  background-color: white;
  /* 导航栏背景色为白色 */
  padding: 10px 30px;
  /* 内边距 */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* 阴影效果 */
  z-index: 1000;
  /* 确保导航栏在其他内容之上 */
}

.logo {
  display: flex;
  align-items: center;
  gap: 15px;
}

.logo a {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.logo-image {
  height: 25px;  /* 调整高度 */
  width: auto;
  object-fit: contain;
  transition: transform 0.3s ease;  /* 添加过渡效果 */
}

.logo-image:hover {
  transform: scale(1.05);  /* 悬停时轻微放大 */
}

.logo-texts {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-top: 2px;  /* 微调文字位置 */
}

.logo-text1 {
  font-size: 14px;
  line-height: 1.1;
  color: #337ECC;
  font-weight: 600;
}

.logo-text2 {
  font-size: 10px;
  color: #999;
  line-height: 1.1;
}

.logo-brand {
  font-size: 36px;
  font-weight: 700;
  color: #ff9900;
  text-decoration:none;
  /* letter-spacing: 1px; */
  font-family: Arial, sans-serif;
}

.nav-links {
  display: flex;
  /* 使用 Flexbox 布局 */
  align-items: center;
  /* 垂直居中 */
}

.nav-item {
  color: black;
  /* 链接颜色为黑色 */
  text-decoration: none;
  /* 去掉下划线 */
  margin-right: 20px;
  /* 右边距 */
  padding: 10px 15px;
  /* 内边距 */
  border-radius: 4px;
  /* 圆角 */
  transition: background-color 0.3s;
  /* 背景色过渡效果 */
}

.nav-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
  /* 悬停时的背景色 */
}

.logout-button {
  margin-left: auto;
}

.main-content {
  flex: 1;
  /* 使内容区域填满剩余空间 */
}

.footer {
  background-color: #1c1f2c;
  color: #fff;
  padding: 40px 0 20px;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.qrcode-section {
  display: flex;
  gap: 30px;
}

.qrcode-item {
  text-align: center;
}

.qrcode-item img {
  width: 120px;
  height: 120px;
  margin-bottom: 10px;
}

.qrcode-item p {
  color: #999;
  font-size: 12px;
}

.contact-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-item .contact-label {
  color: #999;
  font-size: 12px;
  margin-bottom: 5px;
}

.contact-item .contact-value {
  font-size: 20px;
  font-weight: bold;
}

.nav-section {
  display: flex;
  gap: 40px;
}

.nav-group h3 {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: normal;
}

.nav-group ul {
  list-style: none;
  padding: 0;
}

.nav-group ul li {
  margin-bottom: 12px;
}

.nav-group ul li a {
  color: #999;
  text-decoration: none;
  font-size: 14px;
}

.nav-group ul li a:hover {
  color: #fff;
}

.footer-bottom {
  max-width: 1200px;
  margin: 10px auto 0;
  padding: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
}

.footer-links {
  margin-bottom: 20px;
}

.footer-links a {
  color: #999;
  text-decoration: none;
  margin: 0 10px;
  font-size: 14px;
}

.copyright {
  color: #999;
  font-size: 12px;
  line-height: 1.8;
}

.copyright img {
  vertical-align: middle;
  margin-right: 5px;
}
</style>