<template>
  <div class="chat-container">
    <div class="messages" ref="messagesContainer">
      <div v-for="message in messages" :key="message.id" 
           :class="['message', message.is_bot ? 'bot-message' : 'user-message']">
        <div class="message-content">
          <div class="avatar">
            {{ message.is_bot ? 'AI' : '我' }}
          </div>
          <div class="text">
            <template v-if="message.loading">
              <span class="thinking">
                正在思考<span class="dots">...</span>
              </span>
            </template>
            <template v-else>
              <div class="message-text" v-html="formatMessage(message.content)"></div>
            </template>
          </div>
        </div>
      </div>
    </div>
    
    <!-- 添加输入框部分 -->
    <div class="chat-input">
      <input 
        v-model="newMessage"
        @keyup.enter="sendMessage"
        class="message-input"
        placeholder="请输入消息..."
      />
      <div class="button-group">
        <button @click="sendMessage" class="send-btn">发送</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.chat-container {
  display: flex;
  flex-direction: column;
  height: 80vh;
  max-width: 800px;
  margin: 20px auto;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.messages {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  background: #f5f5f5;
}

.message {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.message-content {
  display: flex;
  align-items: flex-start;
  max-width: 80%;
}

.user-message .message-content {
  margin-left: auto;
  flex-direction: row-reverse;
}

.avatar {
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin: 0 10px;
  flex-shrink: 0;
}

.bot-message .avatar {
  background: #1890ff;
  color: white;
}

.user-message .avatar {
  background: #52c41a;
  color: white;
}

.text {
  padding: 12px 16px;
  border-radius: 10px;
  max-width: calc(100% - 60px);
  overflow-wrap: break-word;
}

.bot-message .text {
  background: white;
  border: 1px solid #e8e8e8;
}

.user-message .text {
  background: #1890ff;
  color: white;
}

.chat-input {
  padding: 20px;
  background: white;
  border-top: 1px solid #e8e8e8;
  display: flex;
  gap: 10px;
}

.message-input {
  flex: 1;
  padding: 10px 15px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  transition: all 0.3s;
}

.message-input:focus {
  border-color: #1890ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.button-group {
  display: flex;
  gap: 10px;
}

button {
  padding: 0 20px;
  height: 40px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s;
}

.send-btn {
  background: #1890ff;
  color: white;
}

.send-btn:hover {
  background: #40a9ff;
}

.test-btn {
  background: #52c41a;
  color: white;
}

.test-btn:hover {
  background: #73d13d;
}

/* 滚动条样式 */
.messages::-webkit-scrollbar {
  width: 6px;
}

.messages::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 3px;
}

.messages::-webkit-scrollbar-track {
  background: #f5f5f5;
}

.thinking {
  color: #666;
}

.dots {
  display: inline-block;
  animation: dotAnimation 1.4s infinite;
}

@keyframes dotAnimation {
  0% { opacity: .2; }
  20% { opacity: 1; }
  100% { opacity: .2; }
}

.message-text {
  white-space: pre-line;
  word-break: break-word;
  line-height: 1.6;
}
</style>

<script setup>
import { ref, onMounted, nextTick, watch } from 'vue'
import { useWebSocket } from '@vueuse/core'

const messages = ref([])
const newMessage = ref('')
const messagesContainer = ref(null)
const { data, send, status } = useWebSocket('ws://localhost:8000/ws/chat/', {
  autoReconnect: {
    retries: 3,
    delay: 1000,
    onFailed() {
      // console.error('WebSocket 连接失败')
    }
  },
  onConnected(ws) {
    // console.log('WebSocket 连接成功')
  },
  onMessage(ws, event) {
    try {
      const data = JSON.parse(event.data)
      
      // 处理错误消息
      if (data.type === 'error') {
        if (data.error_type === 'rate_limit') {
          // 显示速率限制提示
          showErrorMessage(data.message);
          // 可以禁用输入框
          disableInput(data.remaining_time);
          return;
        }
      }
      
      // console.log('收到消息:', data)
      
      // 移除加载消息
      messages.value = messages.value.filter(m => !m.loading)
      
      // 添加 AI 的回复
      messages.value.push({
        id: Date.now(),
        content: data.message,
        is_bot: true
      })
      
      scrollToBottom()
    } catch (error) {
      // console.error('处理消息时出错:', error)
    }
  }
})

// 发送消息方法
const sendMessage = async () => {
  if (!newMessage.value.trim()) return
  
  const messageId = Date.now().toString()
  const userMessage = newMessage.value
  
  // 添加用户消息
  messages.value.push({
    id: messageId,
    content: userMessage,
    is_bot: false
  })
  
  // 添加等待消息（使用独立的等待组件）
  messages.value.push({
    id: messageId + '_loading',
    content: '正在思考',
    is_bot: true,
    loading: true
  })
  
  newMessage.value = ''
  await scrollToBottom()
  
  try {
    await send(JSON.stringify({
      message: userMessage,
      id: messageId
    }))
  } catch (error) {
    // console.error('发送消息失败:', error)
  }
}

// 测试按钮直接调用 sendMessage
const sendTestMessage = sendMessage

// 监听连接状态
watch(status, (newStatus) => {
  // console.log('WebSocket 状态:', newStatus)
})

const scrollToBottom = async () => {
  await nextTick()
  if (messagesContainer.value) {
    messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight
  }
}

// 组件挂载后初始化
onMounted(() => {
  scrollToBottom()
})

const formatMessage = (content) => {
  if (!content) return '';
  return content.split('\n').map(line => line.trim()).join('<br>');
}
</script> 