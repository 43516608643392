<template>
  <div class="page-wrapper">
    <div class="page-container">
      <div class="image-container">
        <img src="@/assets/images/logo.png" alt="logo" class="logo-image">
      </div>

      <div class="password-reset-confirm">
        <h2>确认重置密码</h2>
        <el-form :model="form" :rules="rules" ref="formRef" label-width="100px">
          <el-form-item label="新密码" prop="newPassword">
            <el-input v-model="form.newPassword" type="password" required />
          </el-form-item>

          <el-form-item label="确认新密码" prop="confirmPassword">
            <el-input v-model="form.confirmPassword" type="password" required />
          </el-form-item>

          <el-form-item>
            <div class="button-group">
              <el-button type="primary" @click="confirmPasswordReset" :loading="isLoading">
                {{ isLoading ? '处理中...' : '重置密码' }}
              </el-button>
              <div class="links">
                <router-link to="/login" class="link">返回登录</router-link>
              </div>
            </div>
          </el-form-item>
        </el-form>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
        <p v-if="successMessage" class="success-message">{{ successMessage }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import api from '@/services/api'
import { ElMessage } from 'element-plus'

const route = useRoute()
const router = useRouter()

const formRef = ref(null)
const isLoading = ref(false)
const errorMessage = ref('')
const successMessage = ref('')

const form = reactive({
  newPassword: '',
  confirmPassword: ''
})

const rules = {
  newPassword: [
    { required: true, message: '请输入新密码', trigger: 'blur' },
    { min: 8, message: '密码长度至少为 8 个字符', trigger: 'blur' }
  ],
  confirmPassword: [
    { required: true, message: '请确认新密码', trigger: 'blur' },
    {
      validator: (rule, value, callback) => {
        if (value !== form.newPassword) {
          callback(new Error('两次输入的密码不一致'))
        } else {
          callback()
        }
      }, trigger: 'blur'
    }
  ]
}

const confirmPasswordReset = async () => {
  await formRef.value.validate(async (valid) => {
    if (valid) {
      isLoading.value = true
      errorMessage.value = ''
      successMessage.value = ''

      try {
        console.log('Reset password params:', {
          uid: route.params.uid,
          token: route.params.token,
          newPassword: form.newPassword
        });

        const response = await api.resetPasswordConfirm({
          // uid: route.params.uid,
          token: route.query.token,
          new_password: form.newPassword,
          confirm_password: form.confirmPassword
        });

        if (response.data.code === 4010) {
          errorMessage.value = response.data.message
        } else if (response.data.code === 4012) {
          errorMessage.value = response.data.message
        } else {
          ElMessage.success('密码重置成功！');
          // 重置密码成功后，删除记住密码 
          localStorage.removeItem('rememberedUsername')
          localStorage.removeItem('rememberedPassword')
          // setTimeout(() => {
          //   router.push('/login');
          // }, 2000);
        }

      } catch (error) {
        // console.error('重置密码失败', error.response?.data);
        ElMessage.error(error.response?.data?.error || '重置密码失败，请重试');
      } finally {
        isLoading.value = false;
      }
    }
  });
};
</script>

<style scoped>
/* 外层包装容器，用于垂直居中 */
.page-wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: #f5f7fa;
}

/* 页面容器样式 */
.page-container {
  display: flex;
  width: 100%;
  height: auto;
  min-height: 300px;
  max-width: 1080px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

/* 左侧图片容器 */
.image-container {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f7fa;
  border-radius: 4px 0 0 4px;
  overflow: hidden;
}

.logo-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* 右侧重置密码容器 */
.password-reset-confirm {
  width: 45%;
  padding: 30px 30px 30px 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 0 4px 4px 0;
}

.password-reset-confirm h2 {
  padding-left: 30px;
  font-size: 24px;
  font-weight: 600;
}

/* 按钮组和链接样式 */
.button-group {
  display: flex;
  align-items: center;
  gap: 15px;
}

.links {
  display: flex;
  gap: 10px;
}

.link {
  font-size: 14px;
  color: var(--el-text-color-regular);
  text-decoration: none;
}

.link:hover {
  color: var(--el-color-primary);
}

.success-message {
  color: green;
}

.error-message {
  padding-left: 30px;
  font-size: 14px;
  color: red;
}
</style>